<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.stockInTradeToCompany') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.natureActivityuBusinessIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.natureActivityuBusinessIdentification }">
            {{ data.natureActivityuBusinessIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.debitorAddress') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.propertyLocalization }">
            {{ data.propertyLocalization || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.businessNameIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.businessNameIdentification }">
            {{ data.businessNameIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.businessBrandNameIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.businessBrandNameIdentification }">
            {{ data.businessBrandNameIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.businessLocalization') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.businessLocalization }">
            {{ data.businessLocalization || $t('formulaire.notSpecified') }}
          </p>
        </div>
      </div>
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.stockIntradeBeneficiary') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.companyBenefitingFromContributionOfBusinessLegalForm') }}</p>
          <p
            class="flex-column-content"
            :class="{ placeholder: !data.companyBenefitingFromContributionOfBusinessLegalForm }"
          >
            {{ data.companyBenefitingFromContributionOfBusinessLegalForm || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.companyBenefitingFromContributionOfBusinessAddress') }}</p>
          <p
            class="flex-column-content"
            :class="{ placeholder: !data.companyBenefitingFromContributionOfBusinessAddress }"
          >
            {{ data.companyBenefitingFromContributionOfBusinessAddress || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">
            {{ $t('search.companyBenefitingFromContributionOfBusinessConstitutionDepositDate') }}
          </p>
          <p
            class="flex-column-content"
            :class="{ placeholder: !data.companyBenefitingFromContributionOfBusinessConstitutionDepositDate }"
          >
            {{
              data.companyBenefitingFromContributionOfBusinessConstitutionDepositDate
                ? data.companyBenefitingFromContributionOfBusinessConstitutionDepositDate.toLocaleDateString('fr-FR')
                : $t('formulaire.notSpecified')
            }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.companyBenefitingFromContributionOfBusinessDepositNumber') }}</p>
          <p
            class="flex-column-content"
            :class="{ placeholder: !data.companyBenefitingFromContributionOfBusinessDepositNumber }"
          >
            {{ data.companyBenefitingFromContributionOfBusinessDepositNumber || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeclarationsCreances',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
