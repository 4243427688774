<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.shipIdentification') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipName') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipName }">
            {{ data.shipName || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipRegistrationNumber') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipRegistrationNumber }">
            {{ data.shipRegistrationNumber || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column">
          <p class="subtitle">{{ $t('search.otherDetails') }}</p>
          <div class="flex-column-group">
            <p class="flex-column-title">{{ $t('search.shipUnencumberedFractionInterestRates') }}</p>
            <p class="flex-column-content" :class="{ placeholder: !data.shipUnencumberedFractionInterestRates }">
              {{ data.shipUnencumberedFractionInterestRates || $t('formulaire.notSpecified') }}
            </p>
          </div>
          <p class="flex-column-conten flex-column-groupt">
            <b>{{ $t('search.agreementExtraInfos') }}:</b>
            {{ data.agreementExtraInfos || $t('formulaire.notSpecified') }}
          </p>
          <p class="flex-column-content flex-column-group">
            <b>{{ $t('search.ship244Agreement') }}:</b> {{ data.ship244Agreement ? $t('oui') : $t('non') }}
          </p>
          <div class="flex-column-group">
            <p class="flex-column-title">{{ $t('search.boatConstructionDescription') }}</p>
            <p class="flex-column-content" :class="{ placeholder: !data.boatConstructionDescription }">
              {{ data.boatConstructionDescription || $t('formulaire.notSpecified') }}
            </p>
          </div>
          <div class="flex-column-group">
            <p class="flex-column-title">{{ $t('search.observations') }}</p>
            <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
            <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
              {{ mention }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HypothèquesFluviales',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>