export const SET_LIST = 'SET_LIST';
export const CLEAR_LIST = 'CLEAR_LIST';

export const SAVE_LAST_PARAMS = 'SAVE_LAST_PARAMS';
export const CLEAR_LAST_PARAMS = 'CLEAR_LAST_PARAMS';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

export const ADD_NON_POSSESSORY_PLEDGE = 'ADD_NON_POSSESSORY_PLEDGE';
export const REMOVE_NON_POSSESSORY_PLEDGE = 'REMOVE_NON_POSSESSORY_PLEDGE';
export const CLEAR_NON_POSSESSORY_PLEDGE = 'CLEAR_NON_POSSESSORY_PLEDGE_LIST';

export const ADD_SHIP_CATEGORY = 'ADD_SHIP_CATEGORY';
export const REMOVE_SHIP_CATEGORY = 'REMOVE_SHIP_CATEGORY';
export const CLEAR_SHIP_CATEGORY = 'CLEAR_SHIP_CATEGORY';

export const ADD_BOAT_CATEGORY = 'ADD_BOAT_CATEGORY';
export const REMOVE_BOAT_CATEGORY = 'REMOVE_BOAT_CATEGORY';
export const CLEAR_BOAT_CATEGORY = 'CLEAR_BOAT_CATEGORY';

export const SET_DEBITOR_CARD = 'SET_DEBITOR_CARD';

export const SET_LEGAL_STATUS_LIST = 'SET_LEGAL_STATUS_LIST';

export const mutations = {
  [SET_LIST](state, list) {
    state.debitorList = list;
  },

  [CLEAR_LIST](state) {
    state.debitorList = [];
  },

  [SAVE_LAST_PARAMS](state, params) {
    state.lastUsedParams = params;
  },

  [CLEAR_LAST_PARAMS](state) {
    state.lastUsedParams = {};
  },

  [ADD_CATEGORY](state, category) {
    state.categoryList.push(category);
  },

  [REMOVE_CATEGORY](state, category) {
    state.categoryList = state.categoryList.filter((item) => item != category);
  },

  [CLEAR_CATEGORY](state) {
    state.categoryList = [];
  },

  [ADD_NON_POSSESSORY_PLEDGE](state, category) {
    state.nonPossessoryPledgesList.push(category);
  },

  [REMOVE_NON_POSSESSORY_PLEDGE](state, category) {
    state.nonPossessoryPledgesList = state.nonPossessoryPledgesList.filter((item) => item != category);
  },

  [CLEAR_NON_POSSESSORY_PLEDGE](state) {
    state.nonPossessoryPledgesList = [];
  },

  [ADD_SHIP_CATEGORY](state, category) {
    state.shipCategories.push(category);
  },

  [REMOVE_SHIP_CATEGORY](state, category) {
    state.shipCategories = state.shipCategories.filter((item) => item != category);
  },

  [CLEAR_SHIP_CATEGORY](state) {
    state.shipCategories = [];
  },

  [ADD_BOAT_CATEGORY](state, category) {
    state.boatCategories.push(category);
  },

  [REMOVE_BOAT_CATEGORY](state, category) {
    state.boatCategories = state.boatCategories.filter((item) => item != category);
  },

  [CLEAR_BOAT_CATEGORY](state) {
    state.boatCategories = [];
  },

  [SET_DEBITOR_CARD](state, cardId) {
    state.debitorOpenCardId = state.debitorOpenCardId === cardId ? null : cardId;
  },

  [SET_LEGAL_STATUS_LIST](state, list) {
    state.legalStatusList = list;
  },
};
