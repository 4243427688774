<template>
  <div class="version view">Version : {{ version }}</div>
</template>

<script>
export default {
  name: 'Version',
  computed: {
    version() {
      return process.env.VUE_APP_FRONT_VERSION || 'LOCAL';
    },
  },
};
</script>

<style lang="scss" scoped>
.version.view {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  background: #1e1e1f;
  font-family: monospace;
  color: #05a503;
  text-shadow: 0 0 8px rgba(#fff, 0.2);
}
</style>