<template>
  <div class="search view">
    <div class="search-header">
      <button class="primary" @click="$router.push({ name: 'SirenSearch' })">
        <SearchIcon class="icon" />
        <span>{{ $t('nouvelleRecherche') }}</span>
      </button>
    </div>
    <div class="search-body">
      <router-view />
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/icons/search.svg';

export default {
  name: 'Search',
  components: { SearchIcon },
};
</script>

<style lang="scss" scoped>
.search {
  overflow: auto;
  min-height: 100vh;
  .search-header {
    width: 100%;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .logo-container {
      position: absolute;
      left: 32px;
      top: 26px;
    }
  }

  .search-body {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .search {
    .search-header {
      height: 209px;
      align-items: flex-end;
      padding: 24px;
      .logo-container {
        top: 56px;
      }
      .primary {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        padding: 11px 0;
      }
    }
  }
}
</style>