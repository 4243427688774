<template>
  <div class="ad">
    <p>{{ $t('HMPortalAd') }}</p>
    <a href="https://hypothequesmaritimes.cngtc.fr/" target="_blank">{{ $t('discover') }}</a>
  </div>
</template>

<script>
export default {
  name: 'HMPortalAd',
};
</script>

<style scoped lang="scss">
.ad {
  padding: 24px;
  background: $darkblue-color;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.21);

  p {
    color: $white-color;
    font-size: 16px;
    margin-bottom: 16px;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 22px;
  }

  a {
    display: block;
    background: $accent-color;
    color: $white-color;
    margin: 0;
    padding: 12px 48px;
    width: fit-content;
    transition: 0.3s;

    &:hover {
      background: darken($red-color, 10%);
    }
  }
}
</style>
