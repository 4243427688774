import { createGuid } from '@/utils/guid';

export const ADD_TOAST = 'ADD_TOAST';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const mutations = {
  [ADD_TOAST](state, { message, type, duration = 5000 }) {
    const toast = {
      message,
      type,
      duration,
      id: createGuid(),
      time: Date.now(),
    };
    state.toasts.push(toast);

    setTimeout(() => {
      state.toasts = state.toasts.filter((t) => t.id !== toast.id);
    }, duration);
  },
  [CLEAR_TOAST](state) {
    state.toasts = [];
  },
};
