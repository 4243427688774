<template>
  <div class="results-container">
    <SearchFilters />
    <div class="results-area">
      <div class="line-right">
        <button class="ghost no-margin" @click="printResults" :class="{ loading: printing }">
          <DownloadIcon class="icon" />
          <span>{{ $t('search.downloadResults') }}</span>
        </button>
      </div>
      <div class="no-result" v-if="errorMessage">
        <p v-html="errorMessage"></p>
      </div>
      <div class="no-result" v-else-if="!debitorList.length">
        <p>{{ $t('search.noResult') }}</p>
      </div>
      <div class="cards-area" v-else>
        <h5 class="">{{ $t('search.selectDebitor') }}:</h5>
        <div class="results-list">
          <DebitorCard v-for="debitor in debitorList" :key="debitor.siren" :debitor="debitor" />
          <p class="end-of-result">{{ $t('search.endOfResult') }}</p>
        </div>
      </div>
    </div>
    <div class="caution-message">
      <p>{{ $t('search.caution-text-1') }}</p>
      <p>{{ $t('search.caution-text-2') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DebitorCard from '@/components/results/DebitorCard.vue';
import SearchFilters from '@/components/results/SearchFilters.vue';

import DownloadIcon from '@/assets/icons/download.svg';
import ReCaptchaMixin from '@/mixins/ReCaptcha';

export default {
  name: 'Results',
  components: { DownloadIcon, DebitorCard, SearchFilters },
  mixins: [ReCaptchaMixin],
  data() {
    return {
      query: JSON.parse(localStorage.getItem('PRSMdebitorQueries')),
      printing: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      debitorList: 'debitorList',
    }),
  },
  async created() {
    if (!this.debitorList.length && localStorage.getItem('PRSMdebitorQueries')) {
      try {
        const token = await this.getReCaptchaToken('submit')
        await this.$store.dispatch('getDebitorList', { params: this.query, token });
      } catch (e) {
        this.errorMessage = this.$t('error.defaultError', {traceId: e.response.data.traceId});
      }
    }
  },
  methods: {
    async printResults() {
      if (this.printing) return;
      this.printing = true;
      const blob = await this.$store.dispatch('printDebitors', {
        params: JSON.parse(localStorage.getItem('PRSMdebitorQueries')),
        fileName: 'list_suretes.pdf',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'list_suretes.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.printing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.results-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .results-area {
    padding: 32px 14% 0 14%;
    height: 100%;

    .line-right {
      width: 100%;
      min-height: 42px;
      display: flex;
      justify-content: flex-end;
    }

    .no-result {
      height: 124px;
      width: 100%;
      background-color: rgba(39, 55, 78, 0.06);
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      p {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }
    }

    .cards-area {
      margin-top: 32px;

      h5 {
        padding-left: 3%;
        color: $darkblue-color;
        font-family: $title-font;
        font-size: 16px;
        font-weight: bold;
        line-height: 17px;
      }

      .results-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;

        margin-top: 24px;
      }
    }
  }

  .caution-message {
    height: 106px;
    width: 40%;
    margin: 56px 14% 131px 14%;
    background-color: rgba(73, 103, 118, 0.1);
    border: 2px solid rgba(39, 55, 78, 0.3);
    padding: 21px 0 21px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.text-line {
  color: $darkblue-color;
  font-family: $text-font;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
}

.weight-line {
  color: $darkblue-color;
  font-family: $title-font;
  font-size: 16px;
  font-weight: bold;
  line-height: 17px;

  &.smaller {
    font-size: 14px;
  }

  &.siren {
    text-transform: uppercase;
  }
}

.no-margin {
  margin: 0;
}

@media (max-width: $layout-breakpoint-medium) {
  .results-container {
    .results-area {
      padding: 32px 24px 0;
    }

    .caution-message {
      height: unset;
      width: unset;
      margin: 56px 24px;
      padding: 21px;
    }

    .line-right {
      justify-content: center;

      button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
</style>
