<template>
  <div class="toast-container">
    <TransitionGroup name="toast-animation">
      <Toast v-for="toast in toasts" :key="toast.id" :message="toast.message" :type="toast.type" />
    </TransitionGroup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Toast from '@/components/Toast';

export default {
  name: 'Toasts',
  components: { Toast },
  computed: {
    ...mapGetters({ toasts: 'toasts' }),
  },
};
</script>

<style scoped lang="scss">
.toast-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

/* toast-animation */
.toast-animation-enter-active,
.toast-animation-leave-active {
  transition: all 0.5s ease;
}

.toast-animation-move {
  transition: all 0.2s ease;
}

.toast-animation-enter-from {
  opacity: 0;
  transform: translateY(24px);
}

.toast-animation-leave-to {
  opacity: 0;
  transform: translateY(-8px);
}
</style>
