<template>
  <div class="banner" :class="{ print: print }">
    <a class="back-link" @click="$router.go(-1)" v-if="!print">
      <div class="arrow-left"></div>
      <p class="link-text">{{ $t('search.backToResult') }}</p>
    </a>
    <button class="ghost border download" @click="printSuretes" :class="{ loading: printing }" v-if="!print">
      <DownloadIcon class="icon" />
      <span>{{ $t('search.downloadResults') }}</span>
    </button>
    <div class="blue-box">
      <div class="part">
        <h3>{{ `${debitor?.lastName || debitor?.denomination} ${debitor?.firstName || ''}` }}</h3>
        <p>
          {{ debitor?.address.country }} {{ debitor?.address.postalCode ? ` | ${debitor?.address.postalCode}` : '' }}
          {{ debitor?.address.city ? ` | ${debitor?.address.city}` : '' }}
        </p>
        <p>
          {{ debitor?.address.streetNumber ? debitor?.address.streetNumber + ' | ' : '' }}{{ debitor?.address.street }}
        </p>
      </div>
      <div class="part">
        <h3 class="capital">{{ $t('search.siren') }}</h3>
        <p>{{ debitor?.siren || '--' }}</p>
        <p>{{ debitor?.legalStatus?.name }}</p>
      </div>
      <div class="part flex-two" v-if="!print">
        <a
          class="secondary border no-margin link-button"
          :title="infogreffeLink"
          :href="infogreffeLink"
          target="_blank"
        >
          <LinkIcon class="icon" />
          <span>{{ $t('search.orderList') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LinkIcon from '@/assets/icons/iconlink.svg';
import DownloadIcon from '@/assets/icons/download.svg';

export default {
  name: 'DebitorDetailBlock',
  components: {
    DownloadIcon,
    LinkIcon,
  },
  props: {
    debitor: {
      type: Object,
    },
  },
  data() {
    return {
      printing: false,
      infogreffeLink: null,
    };
  },
  computed: {
    print() {
      return this.$route.name === 'DebitorsSuretesPrint';
    },
  },
  async created() {
    this.infogreffeLink = await this.$store.dispatch('getIfgRedirecturl', { siren: this.debitor.siren });
  },
  methods: {
    async printSuretes() {
      if (this.printing) return;
      this.printing = true;
      const blob = await this.$store.dispatch('printDebitorSuretes', {
        identifierDebitor: this.$route.params.id,
        fileName: `suretes_${this.$route.params.id}.pdf`,
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `suretes_${this.$route.params.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.printing = false;
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-height: 102px;
  border-top: 6px solid rgba(39, 55, 78, 0.04);
  padding: 11px 14% 12px 14%;
  margin-top: 30px;

  &.print {
    margin: 0;
    padding: 0;

    .blue-box {
      margin: 0 0 24px;
    }
  }

  .back-link {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;

    .arrow-left {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid $darkblue-color;
      margin: 4px;
    }

    .link-text {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      border-bottom: 1px solid $darkblue-color;
    }
  }

  .download {
    margin: 0 0 0 auto;
  }

  .results-for {
    opacity: 0.21;
    color: $darkblue-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 26px;
    padding-left: 2%;
  }

  .border {
    border: solid 2px mix($darkblue-color, $white-color, 25%);
  }

  .capital {
    text-transform: uppercase;
  }

  .no-margin {
    margin: 0;
  }

  .search-details {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    padding-left: 2%;
  }

  .blue-box {
    width: 100%;
    min-height: 124px;
    background-color: $darkblue-color;
    gap: 30px;
    margin-top: 24px;
    display: flex;
    padding: 24px;

    .part {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;

      &.flex-two {
        flex: 2;
        align-items: flex-end;
      }

      &.wider {
        gap: 14px;
      }

      h3 {
        color: #ffffff;
        font-family: $title-font;
        font-size: 16px;
        font-weight: bold;
        line-height: 17px;
      }

      p {
        color: #ffffff;
        font-family: $text-font;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .banner:not(.print) {
    padding: 12px 24px;

    .back-link {
      margin: 8px 0;
      padding: 0;
    }

    .download {
      margin: 24px 0 0 0;
      width: 100%;
      justify-content: center;
    }

    .blue-box {
      flex-wrap: wrap;

      .part {
        flex: unset;
        flex-shrink: 0;
        width: 100%;
      }
    }
  }
}
</style>
