import * as types from './mutations';

export const actions = {
  sendToast({ commit }, { message, type, duration }) {
    commit(types.ADD_TOAST, { message, type, duration });
  },

  clearToast({ commit }) {
    commit(types.CLEAR_TOAST);
  },
};
