<template>
  <div class="cookie-banner print-hidden" v-if="cookieBanner">
    <p>{{ $t('cookies.bannerDescription') }}</p>
    <router-link :to="{ name: 'Cookies' }" class="link">{{ $t('cookies.readCookiesPage') }}</router-link>
    <button class="refuse" @click="refuseCookie">{{ $t('cookies.refuse') }}</button>
    <button @click="agreeCookie">{{ $t('cookies.agree') }}</button>
  </div>
</template>

<script>
import { enableCookie } from '@/utils/appInsight';
import { enableTracking } from '@/utils/googleAnalytics';

export default {
  name: 'CookieBanner',
  data() {
    return {
      cookieBanner: true,
    };
  },
  created() {
    const cookieAgree = localStorage.getItem('PRSMCookies');
    const cookieRefused = localStorage.getItem('PRSMCookiesRefused');

    if (cookieAgree || cookieRefused) {
      this.cookieBanner = false;
    }
  },
  methods: {
    agreeCookie() {
      localStorage.setItem('PRSMCookies', new Date());
      enableCookie(true);
      enableTracking(true);
      this.cookieBanner = false;
    },
    refuseCookie() {
      localStorage.setItem('PRSMCookiesRefused', new Date());
      enableCookie(false);
      enableTracking(false);
      this.cookieBanner = false;
    },
  },
};
</script>

<style scoped lang="scss">
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $darkblue-color;
  padding: 0 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-color;
  z-index: 100;

  p {
    font-size: 12px;
    line-height: 14px;
    margin-right: 36px;
  }

  .link {
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    margin-right: auto;
    transition: 0.3s;

    &:hover {
      color: $focus-color;
    }
  }

  button {
    margin: 0;
    background: $focus-color;
    color: $white-color;
    height: 40px;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    padding: 0 28px;

    &.refuse {
      background: $darkblue-color;
      &:hover {
        background: lighten($darkblue-color, 10%);
      }
    }

    &:hover {
      background: darken($focus-color, 10%);
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .cookie-banner {
    flex-wrap: wrap;
    padding: 12px 24px;

    .link {
      padding: 0;
      margin: 0;
    }

    button {
      padding: 0 12px;
    }
  }
}
</style>