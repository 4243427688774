import { createStore } from 'vuex';

import toasts from '@/store/toasts';
import debitors from '@/store/debitor';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    toasts,
    debitors,
  },
});
