<template>
  <div class="boat-form">
    <div class="section">
      <h3 class="group-title">{{ $t('formulaire.informations') }}</h3>
      <div class="three-quarter">
        <InputField v-model="boat.BoatRegistrationNumber" :placeholder="$t('formulaire.boatIdNumber')" />
      </div>
    </div>
    <div class="section">
      <h3 class="group-title">{{ $t('formulaire.safetyCategory') }}</h3>
      <div class="checkbox">
        <input
          type="checkbox"
          id="checkbox-1"
          name="checkbox"
          :checked="categoriesSelected.includes('HypothequesFluviales')"
          @input="updateCategories('HypothequesFluviales')"
        />
        <label for="checkbox-1">{{ $t('categories.HypothequesFluviales') }}</label>
      </div>
      <div class="checkbox">
        <input
          type="checkbox"
          id="checkbox-2"
          name="checkbox"
          :checked="categoriesSelected.includes('ActesSaisieBateaux')"
          @input="updateCategories('ActesSaisieBateaux')"
        />
        <label for="checkbox-2">{{ $t('categories.ActesSaisieBateaux') }}</label>
      </div>
      <div class="checkbox">
        <input
          type="checkbox"
          id="checkbox-3"
          name="checkbox"
          :checked="categoriesSelected.includes('ActeJugementTranslatifOuDroitsBateaux')"
          @input="updateCategories('ActeJugementTranslatifOuDroitsBateaux')"
        />
        <label for="checkbox-3" class="translatif">{{ $t('categories.translatifSmall') }}</label>
      </div>
      <span class="error-message" v-if="!categoriesSelected.length">{{ $t('error.noCategoriesSelected') }}</span>
    </div>
    <div class="section">
      <div class="button-container">
        <button
          class="primary contained"
          :disabled="!boat.BoatRegistrationNumber || !categoriesSelected.length"
          @click="getDebitors"
        >
          <SearchIcon class="icon" />
          <span>{{ $t('rechercher') }}</span>
        </button>
      </div>
      <div class="caution-message">{{ $t('formulaire.cautionMessage') }}</div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/icons/search.svg';

import InputField from '@/components/inputs/InputField.vue';
import ReCaptchaMixin from '@/mixins/ReCaptcha';

export default {
  name: 'BoatForm',
  mixins: [ReCaptchaMixin],
  data() {
    return {
      boat: {
        BoatRegistrationNumber: null,
      },
      categoriesSelected: ['HypothequesFluviales', 'ActesSaisieBateaux', 'ActeJugementTranslatifOuDroitsBateaux'],
    };
  },
  components: {
    SearchIcon,
    InputField,
  },
  methods: {
    async getDebitors() {
      try {
        const token = await this.getReCaptchaToken('submit')
        this.boat.Categories = this.categoriesSelected.join(',');
        await this.$store.dispatch('getDebitorList', { params: this.boat, token });
        this.$router.push({ name: 'Debitors', query: this.boat });
      } catch (e) {
        return e
      }
    },
    updateCategories(value) {
      this.categoriesSelected.includes(value)
        ? (this.categoriesSelected = this.categoriesSelected.filter((item) => item !== value))
        : this.categoriesSelected.push(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.boat-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;

    .checkbox {
      margin-left: 0;
    }

    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      .icon-area {
        width: fit-content;
        position: relative;

        .icon {
          width: 24px;
          height: 24px;
        }

        .safety-text {
          color: $darkblue-color;
          font-family: $text-font;
          cursor: default;
          font-size: 10px;
          line-height: 13px;
          padding: 7px 11px 7px 12px;
          position: absolute;
          opacity: 0;
          width: 288px;
          height: 55px;
          right: 0;
          top: 0;
          transform: scale(0);
          transition: all 200ms ease-in-out;

          .background {
            background-color: #cfd4db;
            border-radius: 6px;
            position: absolute;
            inset: 0 0 0 0;
            z-index: 1;
            opacity: 0.6;
          }
          span {
            position: relative;
            z-index: 2;
            display: none;
          }
        }

        &:hover {
          .safety-text {
            transform-origin: top right;
            transform: scale(1) translate(-14px, 25px);
            overflow: hidden;
            opacity: 1;
            transition: all 200ms ease-in-out;

            span {
              display: block;
            }
          }
        }
      }
    }

    .safety-cat {
      width: 100%;
      height: 42px;
      border: 2px solid #c9cdd2;
      padding: 13px 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #bec3c8;
      font-family: $text-font;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 13px;
      overflow: hidden;
    }

    .group-title {
      color: $darkblue-color;
      font-size: 16px;
      font-weight: bold;
      line-height: 17px;
      margin: 8px 0;
    }

    .three-quarter {
      width: 75%;
    }
  }

  .button-container {
    height: 40px;
    width: 168px;
  }

  .button-text {
    text-transform: capitalize;
  }

  .caution-message {
    height: auto;
    width: 100%;
    border: 2px solid rgba(39, 55, 78, 0.3);
    background-color: rgba(73, 103, 118, 0.1);
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 10px;
  }
}

.error-message {
  color: $accent-color;
  font-family: $text-font;
  font-size: 10px;
  line-height: 11px;
}
</style>
