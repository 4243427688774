<template>
  <input
    class="input-field"
    :type="type === 'siren' ? 'text' : type"
    :value="modelValue"
    @input="update"
    @blur="blur"
    :placeholder="placeholder"
    :disabled="disabled"
    :maxLength="maxLength"
  />
</template>

<script>
export default {
  name: 'InputField',
  emits: ['update:modelValue', 'blur', 'change'],
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: String,
      default: null,
    },
  },
  methods: {
    update(e) {
      if (this.type === 'siren') {
        let sirenString = e.target.value
          ?.split('')
          .filter((v) => v !== ' ' && !isNaN(Number(v)))
          .join('');

        if (sirenString?.length) {
          sirenString = sirenString.match(/.{1,3}/g)?.join(' ');
        }
        e.target.value = sirenString;
      }
      this.$emit('change');
      this.$emit('update:modelValue', e.target.value);
    },
    blur() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  margin: 0;
}
</style>