<template>
  <div class="categories-details">
    <h3 class="title">{{ $t('search.details') }}</h3>
    <div class="flex-column">
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.propertyDescription') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.propertyDescription }">
          {{ data.propertyDescription || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.debitorAddress') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.propertyLocalization }">
          {{ data.propertyLocalization || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDate') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDate }">
          {{ formatDate(data.actOrCourtDecisionDate) || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.actOrCourtDecisionType') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionType }">
          {{ data.actOrCourtDecisionType || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.actOrCourtDecisionAuthor') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionAuthor }">
          {{ data.actOrCourtDecisionAuthor || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDuration') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDuration }">
          {{ data.actOrCourtDecisionDuration || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.debitorBranchOfficesDescription') }}</p>
        <p class="flex-column-content" :class="{ placeholder: !data.debitorBranchOfficesDescription }">
          {{ data.debitorBranchOfficesDescription || $t('formulaire.notSpecified') }}
        </p>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-content">
          <b>{{ $t('search.legal1416CodeDescription') }}:</b>
          {{ data.legal1416CodeDescription || $t('formulaire.notSpecified') }}
        </p>
        <p class="flex-column-content">
          <b>{{ $t('search.agreementExtraInfos') }}:</b>
          {{ data.agreementExtraInfos || $t('formulaire.notSpecified') }}
        </p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.natureActivityuBusinessIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.natureActivityuBusinessIdentification }">
            {{ data.natureActivityuBusinessIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.totalPrice') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.totalPrice }">
            {{ totalPrice || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.equipmentPrice') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.equipmentPrice }">
            {{ data.equipmentPrice || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.merchandisePrice') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.merchandisePrice }">
            {{ data.merchandisePrice || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.intangibleAssetsPrice') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.intangibleAssetsPrice }">
            {{ data.intangibleAssetsPrice || $t('formulaire.notSpecified') }}
          </p>
        </div>
      </div>
      <div class="flex-column-group">
        <p class="flex-column-title">{{ $t('search.observations') }}</p>
        <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
        <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
          {{ mention }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/formater';

export default {
  name: 'PrivilegeVendeurFondsDeCommerce',
  data() {
    return {
      formatDate,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalPrice() {
      const result = this.data.equipmentPrice + this.data.merchandisePrice + this.data.intangibleAssetsPrice;

      return !result ? null : `${result / 100}€`;
    },
  },
};
</script>
