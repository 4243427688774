export default  {
  methods: {
    async getReCaptchaToken(action) {
      try {
        await this.$recaptchaLoaded()

        return await this.$recaptcha(action);
      } catch (e) {
        return e
      }

    }
  }
}
