<template>
  <router-link :to="isAlreadyOnHomePage ? '' : {name: 'SirenSearch'}" class="logo">
    <Logo class="head" />
    <div class="text-container">
      <div class="line line-1">{{ $t('logo-titre.ligne-1') }}</div>
      <div class="line line-2">{{ $t('logo-titre.ligne-2') }}</div>
    </div>
  </router-link>
</template>

<script>
import Logo from '@/assets/logo.svg';

export default {
  name: 'CNGLogo',
  components: {
    Logo,
  },
  computed: {
    isAlreadyOnHomePage() {
      return this.$route.meta.home;
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  position: relative;
  width: fit-content;
  height: 48px;
  overflow: visible;

  display: flex;
  align-items: center;

  box-shadow: 0 0 0 0 $white-color, 0 0 0 0 $focus-color;
  border-radius: 4px;
  transition: 0.2s ease-out;

  .head {
    $radius: 48px;
    height: $radius;
    width: $radius;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .text-container {
    font-family: $title-font;
    font-weight: 700;
    font-size: 12px;
    color: $blue-color;
    padding-top: 3px;

    transition: 0.6s;

    .line {
      margin-bottom: 8px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 10px $white-color, 0 0 0 12px $focus-color;
  }

  &:hover {
    .text-container {
      opacity: 0.8;
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .logo {
    .text-container {
      font-size: 10px;
    }
  }
}
</style>
