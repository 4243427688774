<template>
  <div class="banner" :class="{ print: print }">
    <h4 class="results-for">{{ $t('search.searchFor') }}:</h4>
    <div class="search-details">
      <p class="text-line">{{ queryFilters }}</p>
      <p class="text-line" v-if="query.Categories">
        {{ getCategories }}
      </p>
      <p class="text-line" v-else>
        {{ $t('search.allSureteCategories') }}
      </p>
      <p class="text-line" v-if="query.Subcategories">
        {{ getSubcategories }}
      </p>
    </div>
  </div>
</template>

<script>
import { mainCategories, nonPossessoryPledges } from '@/utils/infoEnum';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchFilter',
  data() {
    return {
      query: JSON.parse(localStorage.getItem('PRSMdebitorQueries')),
    };
  },
  computed: {
    ...mapGetters({
      legalStatusList: 'legalStatusList',
    }),
    print() {
      return this.$route.name === 'DebitorsPrint';
    },
    queryFilters() {
      const categoriesToExclude = ['Categories', 'Subcategories', 'typesSuretes', 'token'];

      return Object.entries(this.query)
        .filter((value) => !categoriesToExclude.includes(value[0]))
        .filter((value) => value[1])
        .map((value) => {
          if (value[0] === 'siren') {
            return value[1].replace(/.{3}/g, '$& ');
          } else if (value[0] === 'legalStatus') {
            return this.legalStatusList?.find((item) => item.code === value[1]).name;
          } else {
            return value[1];
          }
        })
        .join(' | ');
    },
    getCategories() {
      return this.query.Categories.split(',')
        .map((item) => {
          const translation = mainCategories.find((category) => category.bigramme === item).translation;
          return this.$t(`categories.${translation}`);
        })
        .join(' | ');
    },
    getSubcategories() {
      return this.query.Subcategories.split(',')
        .map((item) => {
          const translation = nonPossessoryPledges.find((category) => category.bigramme === item).translation;
          return this.$t(`categories.${translation}`);
        })
        .join(' | ');
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  min-height: 102px;
  background-color: rgba(39, 55, 78, 0.04);
  padding: 11px 18% 12px 18%;

  &.print {
    padding-top: 10mm;
    padding: 24px;
  }

  .results-for {
    opacity: 0.21;
    color: $darkblue-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 26px;
  }

  .search-details {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .banner {
    padding: 12px 24px;
  }
}
</style>
