export const getters = {
  debitorList: ({ debitorList }) => debitorList,
  lastUsedParams: ({ lastUsedParams }) => lastUsedParams,
  categoryList: ({ categoryList }) => categoryList,
  nonPossessoryPledgesList: ({ nonPossessoryPledgesList }) => nonPossessoryPledgesList,
  shipCategories: ({ shipCategories }) => shipCategories,
  boatCategories: ({ boatCategories }) => boatCategories,
  debitorOpenCardId: ({ debitorOpenCardId }) => debitorOpenCardId,
  legalStatusList: ({ legalStatusList }) => legalStatusList,
};
