import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state = {
  toasts: [],
};

const namespaced = false;

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
