<template>
  <div class="view">
    <Surete :data="surete" v-if="surete && debitor" :detailedPrint="true" :debitor="debitor" />
    <div class="caution-message">
      <p>{{ $t('search.cautionDebitorsMessage') }}</p>
    </div>
  </div>
</template>

<script>
import Surete from '@/components/results/surete/Surete.vue';
export default {
  name: 'SuretePrint',
  components: {
    Surete,
  },
  data() {
    return {
      surete: null,
      debitor: null,
    };
  },
  async created() {
    const result = await this.$store.dispatch('getOneSurete', {
      identifierDebitor: this.$route.params.id,
      identifierSurete: this.$route.params.identifierSurete,
    });

    this.surete = result.suretes[0];
    this.debitor = result;
  },
};
</script>

<style scoped lang="scss">
.view {
  padding: 10mm;
}

.caution-message {
  display: flex;
  margin: 56px 0 0;
  background-color: rgba(73, 103, 118, 0.1);
  border: 2px solid rgba(39, 55, 78, 0.3);
  padding: 21px 0 21px 24px;
  flex-direction: column;
  justify-content: space-between;

  p {
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 10px;
    line-height: 16px;
  }
}
</style>