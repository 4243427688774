<template>
  <div class="no-siren-form">
    <div class="section">
      <div class="line warning">
        <InfoIcon />
        <p>{{ $t('formulaire.cautionAddressMessage') }}</p>
      </div>
      <div class="line">
        <h3 class="group-title">{{ $t('formulaire.adresse') }}</h3>
      </div>
    </div>
    <div class="section">
      <div class="line">
        <div class="full">
          <InputField v-model="debitor.CountryDebitor" :placeholder="`${$t('formulaire.country')}*`" />
        </div>
      </div>
      <div class="line">
        <div class="quarter">
          <InputField v-model="debitor.PostalCodeDebitor" :placeholder="`${$t('formulaire.zipCode')}*`" />
        </div>
        <div class="three-quarter">
          <InputField v-model="debitor.CityDebitor" :placeholder="`${$t('formulaire.city')}*`" />
        </div>
      </div>
      <div class="line">
        <div class="full">
          <InputField v-model="debitor.StreetNameDebitor" :placeholder="`${$t('formulaire.streetNumberName')}*`" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="button-container">
        <button class="primary contained" :disabled="cantBeSent" @click="getDebitors">
          <SearchIcon class="icon" />
          <span>{{ $t('rechercher') }}</span>
        </button>
      </div>
      <div class="caution-message">{{ $t('formulaire.cautionMessage') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InputField from '@/components/inputs/InputField.vue';

import SearchIcon from '@/assets/icons/search.svg';
import InfoIcon from '@/assets/icons/info.svg';
import ReCaptchaMixin from '@/mixins/ReCaptcha';

export default {
  name: 'AddressForm',
  components: { InputField, SearchIcon, InfoIcon },
  mixins: [ReCaptchaMixin],
  data() {
    return {
      cantBeSent: true,
      debitor: {
        CountryDebitor: 'France',
        CityDebitor: '',
        PostalCodeDebitor: '',
        StreetNameDebitor: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      categoryList: 'categoryList',
      nonPossessoryPledgesList: 'nonPossessoryPledgesList',
    }),
  },
  watch: {
    debitor: {
      deep: true,
      handler() {
        this.checkInputs();
      },
    },
  },
  methods: {
    async getDebitors() {
      const token = await this.getReCaptchaToken('submit')

      await this.$store.dispatch('getDebitorList', { params: this.debitor, token });
      this.$router.push({ name: 'Debitors', query: this.debitor });
    },
    checkInputs() {
      let inputsAreFilledIn = false;
      inputsAreFilledIn =
        this.debitor.CityDebitor &&
        this.debitor.PostalCodeDebitor &&
        this.debitor.StreetNameDebitor &&
        this.debitor.CountryDebitor;

      if (inputsAreFilledIn) this.cantBeSent = false;
      else this.cantBeSent = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-siren-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;

    .line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      label {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 12px;
        line-height: 13px;
        margin-top: -10px;
        height: 17px;
        width: 100%;
      }

      .group-title {
        color: $darkblue-color;
        font-size: 16px;
        font-weight: bold;
        line-height: 17px;
        margin: 8px 0 -22px;
      }

      &.warning {
        gap: 11px;

        svg {
          width: 24px;
          flex-shrink: 0;
        }

        p {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    .button-container {
      height: 40px;
      width: 168px;
    }

    .caution-message {
      width: 100%;
      border: 2px solid rgba(39, 55, 78, 0.3);
      background-color: rgba(73, 103, 118, 0.1);
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 15px;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
}
.button-text {
  text-transform: capitalize;
}
.space {
  justify-content: space-between;
}

.full {
  width: 100%;
}
.half {
  width: 50%;
}
.quarter {
  width: 25%;
}

.three-quarter {
  width: 75%;
}

@media (max-width: $layout-breakpoint-medium) {
  .no-siren-form {
    .section {
      .line {
        input {
          height: 45px;
        }
      }
    }
  }
}
</style>
