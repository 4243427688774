<template>
  <div class="view home">
    <div class="logo-container">
      <Logo />
    </div>
    <div class="title-container">
      <div class="title">
        <h1>{{ $t('titre') }}</h1>
        <p>{{ $t('description') }}</p>
      </div>
      <HMPortalAd class="ad" />
    </div>
    <div class="form-container">
      <div class="sub-container">
        <h2>{{ $t('formulaire.titre-debiteur') }}</h2>
        <Tabs :options="tabs.filter((tab) => tab.active)" v-model="currentForm" />

        <router-view v-slot="{ Component }">
          <transition name="translate-animation" :class="`${animationDirection}-transition`">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/CNGLogo';
import HMPortalAd from '@/components/HMPortalAd';
import Tabs from '@/components/inputs/Tabs';

export default {
  name: 'Home',
  components: { Logo, Tabs, HMPortalAd },
  data() {
    return {
      tabs: [
        {
          label: this.$t('formulaire.avecSiren'),
          value: 'SirenSearch',
          active: true,
        },
        {
          label: this.$t('formulaire.sansSiren'),
          value: 'NoSirenSearch',
          active: true,
        },
        {
          label: this.$t('formulaire.numeroNavire'),
          value: 'ShipSearch',
          active: false,
        },
        {
          label: this.$t('formulaire.numeroBateau'),
          value: 'BoatSearch',
          active: false,
        },
        {
          label: this.$t('formulaire.adresse'),
          value: 'AddressSearch',
          active: false,
        },
      ],
      currentForm: '',
      animationDirection: 'left',
    };
  },
  created() {
    this.resetTab();
  },
  watch: {
    currentForm(newValue, oldValue) {
      this.animationDirection =
        this.tabs.findIndex((tab) => oldValue === tab.value) > this.tabs.findIndex((tab) => newValue === tab.value)
          ? 'right'
          : 'left';

      this.$router.push({ name: newValue });
    },
    $route() {
      this.currentForm = this.$route.name;
      this.$store.dispatch('clearCategoryList');
      this.$store.dispatch('clearNonPossessoryPledgeCategory');
    },
  },
  methods: {
    resetTab() {
      this.currentForm = 'SirenSearch';
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;

  .logo-container {
    position: absolute;
    z-index: 100;
    top: 50px;
    left: 100px;
  }

  .title-container {
    position: relative;
    z-index: 20;
    width: 50%;
    background-image: url('~@/assets/image_home.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .title {
      $gradient-offset: 64px;
      $gradient-opacity: 0.6;

      margin-top: 18vh;
      padding: #{$gradient-offset * 3/4} 100px;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, $gradient-opacity) $gradient-offset,
        rgba(255, 255, 255, $gradient-opacity) calc(100% - $gradient-offset),
        rgba(255, 255, 255, 0) 100%
      );

      h1 {
        font-size: 2.2vw;
        max-width: 80%;
        line-height: 2.2vw;
        text-shadow: 0 1px 3px rgba($darkblue-color, 0.2);
        margin-bottom: 17px;
      }

      p {
        max-width: 70%;
        font-size: 0.9vw;
      }
    }

    .ad {
      position: absolute;
      right: 100px;
      bottom: 50px;
      left: 100px;
    }
  }

  .form-container {
    width: 50%;
    height: 100vh;
    padding: 20vh 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;

    .sub-container {
      position: relative;
      width: 50%;
      min-width: 395px;
    }
  }
}

/* translate-animation */
.translate-animation-enter-active,
.translate-animation-leave-active {
  position: absolute;
  max-width: inherit;
  transition: all 0.8s ease;
}

.translate-animation-enter-from {
  opacity: 0;

  &.left-transition {
    transform: translateX(100%);
  }

  &.right-transition {
    transform: translateX(-100%);
  }
}

.translate-animation-leave-to {
  opacity: 0;

  &.left-transition {
    transform: translateX(-100%);
  }

  &.right-transition {
    transform: translateX(100%);
  }
}

@media (max-width: $layout-breakpoint-extralarge) {
  .home {
    .logo-container {
      left: 50px;
    }
    .title-container {
      .title {
        padding-left: 50px;
        p {
          max-width: 480px;
          font-size: 16px;
        }
        h1 {
          max-width: 560px;
          font-size: 2.7vw;
          line-height: 3.1vw;
        }
      }
      .ad {
        right: 50px;
        left: 50px;
      }
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .home {
    flex-direction: column;

    .logo-container {
      top: 56px;
      left: 24px;
    }

    .title-container {
      width: 100vw;

      .ad {
        display: none;
      }

      .title {
        margin-top: 170px;
        padding: 0 24px;

        h1 {
          font-size: 24px;
          line-height: 32px;
        }

        p {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 102px;
        }
      }
    }

    .form-container {
      width: 100vw;
      padding: 24px 24px 65px 24px;
      min-height: 85vh;
      height: auto;
      overflow: auto;

      .sub-container {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>