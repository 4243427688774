<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.propertyDescription') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.propertyDescription }">
            {{ data.propertyDescription || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.debitorAddress') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.propertyLocalization }">
            {{ data.propertyLocalization || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.debitorBranchOfficesDescription') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.debitorBranchOfficesDescription }">
            {{ data.debitorBranchOfficesDescription || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDate }">
            {{ formatDate(data.actOrCourtDecisionDate) || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionType') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionType }">
            {{ data.actOrCourtDecisionType || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionAuthor') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionAuthor }">
            {{ data.actOrCourtDecisionAuthor || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDuration') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDuration }">
            {{ data.actOrCourtDecisionDuration || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.natureActivityuBusinessIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.natureActivityuBusinessIdentification }">
            {{ data.natureActivityuBusinessIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/formater';

export default {
  name: 'NantissementsFondsDeCommerce',
  data() {
    return {
      formatDate,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
