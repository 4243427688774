<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.employerRegistrationNumber') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.employerRegistrationNumber }">
            {{ data.employerRegistrationNumber || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivilegesSecuriteSocialeRegimesComplementaires',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>