<template>
  <div class="view legal">
    <div class="search-header">
      <router-link class="logo-container" :to="{ name: 'Home' }">
        <Logo />
      </router-link>
    </div>
    <div class="content">
      <h1>Mentions Légales</h1>
      <h2>PRÉSENTATION GÉNÉRALE</h2>
      <div class="container">
        <p>Conseil National des Greffiers des Tribunaux de Commerce (CNGTC)</p>
        <p>Siège social : 29 rue Danielle Casanova - 75001 Paris</p>
        <p>Directeur de la publication : M. Thomas DENFER</p>
        <p>Hébergement : MICROSOFT FRANCE - 37 QUAI DU PRESIDENT ROOSEVELT 92130 ISSY-LES-MOULINEAUX</p>
        <p>
          L'éditeur s'engage à respecter l'ensemble des lois concernant la mise en place et l'activité d'un site
          Internet.
        </p>
      </div>
      <h2>INFORMATIONS TECHNIQUES</h2>
      <div class="container">
        <p>
          Il est rappelé que le secret des correspondances n'est pas garanti sur le réseau Internet et qu'il appartient
          à chaque utilisateur d'Internet de prendre toutes les mesures appropriées de façon à protéger ses propres
          données et/ou logiciels de la contamination d'éventuels virus circulant sur Internet.
        </p>
        <p>
          Le CNG s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées sur ce site, dont il se
          réserve le droit de corriger, à tout moment et sans préavis, le contenu. Il ne peut cependant en garantir
          l'exhaustivité ou l'absence de modification par un tiers (intrusion, virus). En outre, le CNG décline toute
          responsabilité (directe ou indirecte) en cas de retard, d'erreur ou d'omission quant au contenu des présentes
          pages et à l´utilisation qui pourrait en être faite par quiconque de même qu'en cas d'interruption ou de
          non-disponibilité du service. Les informations contenues sur le présent site sont destinées à un usage
          personnel et non commercial
        </p>
      </div>
      <h2>DONNÉES PERSONNELLES</h2>
      <div class="container">
        <p>
          Les informations que vous aurez saisies sur le site du Conseil National des Greffiers pourront être
          enregistrées pour leur traitement exclusif par le Conseil National des Greffiers . Vous disposez d'un droit
          d'accès, de modification, de rectification et de suppression concernant les données collectées sur ce site,
          dans les conditions prévues par la loi n°78-17 du 6 janvier 1978 (modifiée) relative à l'informatique, aux
          fichiers et aux libertés. Pour l'exercer, adressez-vous au gestionnaire du site :
        </p>
        <p>par courrier à l'adresse suivante :</p>
        <div class="address">
          <p>Conseil National des Greffiers des Tribunaux de Commerce (CNG)</p>
          <p>29 rue Danielle Casanova - 75001 Paris</p>
        </div>
        <p>par E-mail à l'adresse suivante : contact@cngtc.fr</p>
      </div>
      <h2>ANNUAIRE DES GREFFIERS</h2>
      <div class="container">
        <p>
          Les données que vous trouverez au sein de cet annuaire de la profession des Greffiers des Tribunaux de
          Commerce ont été communiquées au CNG par les greffiers. Les modifications de coordonnées sont à signaler au
          secrétariat du Conseil National (adresse indiquée ci-dessus).
        </p>
      </div>
      <h2>LIENS HYPERTEXTES</h2>
      <div class="container">
        <p>
          La création de liens hypertextes vers le site du Conseil National des Greffiers est soumis à l'accord
          préalable du Directeur de la Publication. Les liens hypertextes établis en direction d'autres sites à partir
          du site du Conseil National des Greffiers ne sauraient, en aucun cas, engager la responsabilité du Conseil
          National des Greffiers.
        </p>
      </div>
      <h2>DROITS D'AUTEURS</h2>
      <div class="container">
        <p>
          La reproduction ou représentation, intégrale ou partielle, des pages, des données et de toute autre élément
          constitutif au site, par quelque procédé ou support que ce soit, est interdite et constitue sans autorisation
          de l'éditeur une contrefaçon.
        </p>
      </div>
      <h2>CRÉDITS</h2>
      <div class="container">
        <p>
          Ce site a été réalisé avec ♥ par l'équipe de
          <a href="https://latelier.co">L'Atelier</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/CNGLogo';

export default {
  name: 'Legal',
  components: {
    Logo,
  },
};
</script>

<style scoped lang="scss">
.view.legal {
  padding: 0 0 96px;

  h1 {
    color: $blue-color;
    font-family: $title-font;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  h2 {
    color: $darkblue-color;
    font-family: $title-font;
    font-size: 24px;
    font-weight: bold;
    margin: 24px 0 16px;
  }

  .address {
    padding-left: 12px;
    margin: 16px 0;
  }

  p {
    color: #777;
    margin: 2px 0 4px;
    line-height: 24px;

    a {
      text-decoration: underline;
      transition: 0.2s;

      &:hover {
        color: $focus-color;
      }
    }
  }
}

.search-header {
  width: 100%;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .logo-container {
    position: absolute;
    left: 32px;
    top: 26px;
  }
}
</style>
