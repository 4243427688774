<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.warrantedGoodsIdentifications') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.natureActivityuBusinessIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.natureActivityuBusinessIdentification }">
            {{ data.natureActivityuBusinessIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.debitorAddress') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.specialization?.propertyLocalization }">
            {{ data.specialization?.propertyLocalization || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.businessNameIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.businessNameIdentification }">
            {{ data.businessNameIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.businessBrandNameIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.businessBrandNameIdentification }">
            {{ data.businessBrandNameIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.businessLocalization') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.businessLocalization }">
            {{ data.businessLocalization || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDate }">
            {{ formatDate(data.actOrCourtDecisionDate) || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionType') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionType }">
            {{ data.actOrCourtDecisionType || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionAuthor') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionAuthor }">
            {{ data.actOrCourtDecisionAuthor || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDuration') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDuration }">
            {{ data.actOrCourtDecisionDuration || $t('formulaire.notSpecified') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/formater';
export default {
  name: 'MesuresInalienabiliteTribunal',
  data() {
    return {
      formatDate,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
