<template>
  <div class="categories-selector" :class="{ open: open }">
    <div class="value" @click="toggleSelector">
      <span>{{ getListHeader }}</span>
      <div class="arrow" :class="{ up: open }"></div>
    </div>
    <div class="options-container">
      <div class="option" @click="updateList('')">
        <span class="all-cat">{{ $t('formulaire.allCategories') }}</span>
      </div>
      <div
        class="option checkbox"
        :title="$t(`categories.${option.translation}`)"
        :class="{ disabled: !option.active }"
        @click.prevent="updateList(option)"
        v-for="option in options"
        :key="option.bigramme"
      >
        <input type="checkbox" :id="option" name="checkbox" :checked="chosenValues.includes(option.bigramme)" />
        <label :for="option">
          <span>{{ $t(`categories.${option.translation}`) }}</span>
        </label>
      </div>
    </div>
  </div>
  <div class="click-outside" v-if="open" @click="toggleSelector"></div>
</template>

<script>
export default {
  name: 'DropdownInput',
  data() {
    return {
      open: false,
    };
  },
  emits: ['updateList'],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    chosenValues: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getListHeader() {
      if (!this.chosenValues.length) return this.$t('formulaire.allCategories');
      else if (this.chosenValues.length === 1) {
        const translation = this.options.find((option) => option.bigramme === this.chosenValues[0]).translation;
        return this.$t(`categories.${translation}`);
      } else return `${this.chosenValues.length} ${this.$t('formulaire.multipleCategoriesSelected')}`;
    },
  },
  methods: {
    toggleSelector() {
      this.open = !this.open;
    },
    updateList(value) {
      if (value && !value.active) return;
      this.$emit('updateList', value.bigramme);
      if (!value) this.toggleSelector();
    },
  },
};
</script>

<style lang="scss" scoped>
.categories-selector {
  height: 42px;
  width: 100%;
  border: 2px solid #caced3;
  padding: 0 12px 0 16px;
  cursor: pointer;
  position: relative;
  transition: all 100ms ease-in-out;
  box-shadow: 0 0 2px rgba(20, 27, 39, 0);

  &.open {
    box-shadow: 0 1px 4px rgba(39, 55, 78, 0.25);
    border: 2px solid #939ba7;
    transition: all 100ms ease-in-out;

    .options-container {
      max-height: 160px;
      border-bottom: 2px solid #caced3;
      z-index: 3;
      box-shadow: 0 5px 4px rgba(39, 55, 78, 0.25);
      transition: max-height 0.3s ease-in, border-bottom 0.05s;
      border: 2px solid #939ba7;
    }
  }

  .value {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 12px;
      font-weight: bold;
      line-height: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .arrow {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $darkblue-color;
      transition: all 300ms ease-in-out;

      &.up {
        transform: rotate(180deg);
      }
    }
  }

  .options-container {
    position: absolute;
    max-height: 0;
    width: 100%;
    z-index: 2;
    width: auto;
    left: 0;
    right: 0;
    border: 2px solid #caced3;
    border-bottom: 0 solid #caced3;
    overflow-y: auto;
    background-color: white;
    margin-left: -2px;
    margin-right: -2px;

    transition: max-height 0.3s ease-out, border-bottom 0.05s 0.25s;

    .option {
      width: 100%;
      min-height: 42px;
      margin: 0;
      padding: 12px 14px 0 18px;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      input {
        display: none;
      }

      &:hover {
        background-color: #ebf5fb;
      }

      .all-cat {
        font-weight: normal;
        margin-left: 0;
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
}

.click-outside {
  position: fixed;
  z-index: 1;
  inset: 0 0 0 0;
}
</style>