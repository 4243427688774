export const mainCategories = [
  {
    name: 'Gages sans dépossession',
    bigramme: 'GD',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyDescription',
      'propertyLocalization',
      'mentionOfCommissaryAgreement',
      'pledge2342Agreement',
      'pledgeTransferAgreement',
    ],
    component: 'GageSansDepossession',
    translation: 'GageSansDepossession',
    active: true,
  },
  {
    name: 'Nantissements de parts de sociétés ',
    bigramme: 'NP',
    fields: [
      'legalStatus',
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyType',
      'legalForm',
      'denomination',
      'address',
      'siren',
      'rcsDescription',
      'socialSharesValues',
      'agreement',
      'pledge2342Agreement',
      'mentionOfCommissaryAgreement',
    ],
    component: 'NantissementsPartsSocietes',
    translation: 'NantissementsPartsSocietes',
    active: true,
  },
  {
    name: 'Privilège du vendeur de fonds de commerce',
    bigramme: 'VF',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyDescription',
      'equipmentPrice',
      'merchandisePrice',
      'intangibleAssetsPrice',
      'debitorAddress',
      'debitorBranchOfficesDescription',
      'debitorExtraInfos',
      'legal1416CodeDescription',
      'agreementExtraInfos',
    ],
    component: 'PrivilegeVendeurFondsDeCommerce',
    translation: 'PrivilegeVendeurFondsDeCommerce',
    active: true,
  },
  {
    name: 'Nantissements du fonds de commerce',
    bigramme: 'FC',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyDescription',
      'debitorBranchOfficesDescription',
      'debitorExtraInfos',
    ],
    component: 'NantissementsFondsDeCommerce',
    translation: 'NantissementsFondsDeCommerce',
    active: true,
  },
  {
    name: "Déclarations de créances en cas d'apport de fonds de commerce",
    bigramme: 'DC',
    fields: ['inscriptionDate', 'effectiveDate', 'dueDate', 'amount', 'currency', 'interestRates'],
    component: 'DeclarationsCreances',
    translation: 'DeclarationsCreances',
    active: true,
  },
  {
    name: 'Hypothèques maritimes',
    bigramme: 'HM',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipUnencumberedFractionInterestRates',
      'shipClaimAmountDetails',
      'shipName',
      'shipRegitrationPort',
      'shipRegistrationNumber',
      'ship244Agreement',
    ],
    component: 'HypothequesMaritimes',
    translation: 'HypothequesMaritimes',
    active: false,
  },
  {
    name: 'Actes de saisie sur les navires',
    bigramme: 'SM',
    fields: ['inscriptionDate', 'EffectiveDate', 'shipClaimAmountDetails', 'shipName', 'shipRegitrationPort'],
    component: 'ActesSaisieNavires',
    translation: 'ActesSaisieNavires',
    active: false,
  },
  {
    name: 'Acte ou jugement translatif, constitutif ou déclaratif de propriété ou de droits réels portant sur un bateau',
    bigramme: 'JF',
    fields: ['boatName', 'boatRegistrationDate', 'boatRegistrationrNumber', 'boatConstructionDescription'],
    component: 'ActeJugementTranslatifOuDroitsBateaux',
    translation: 'ActeJugementTranslatifOuDroitsBateaux',
    active: false,
  },
  {
    name: 'Hypothèques fluviales',
    bigramme: 'HF',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'boatUnencumberedFractionInterestRates',
      'shipClaimAmountDetails',
      'boatName',
      'boatRegistrationDate',
      'boatRegistrationrNumber',
      'boatConstructionDescription',
    ],
    component: 'HypothequesFluviales',
    translation: 'HypothequesFluviales',
    active: false,
  },
  {
    name: 'Actes de saisie de bateaux',
    bigramme: 'SF',
    fields: ['inscriptionDate', 'effectiveDate'],
    component: 'ActesSaisieBateaux',
    translation: 'ActesSaisieBateaux',
    active: false,
  },
  {
    name: 'Mesures d’inaliénabilité décidées par le tribunal',
    bigramme: 'MI',
    fields: ['inscriptionDate', 'effectiveDate', 'legalIdentity', 'legalIdentityDate', 'inalienabilityDuration'],
    component: 'MesuresInalienabiliteTribunal',
    translation: 'MesuresInalienabiliteTribunal',
    active: true,
  },
  {
    name: 'Clauses de réserve de propriété',
    bigramme: 'RP',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyLocalization',
      'propertyDescription',
    ],
    component: 'ClausesReservePropriete',
    translation: 'ClausesReservePropriete',
    active: true,
  },
  {
    name: 'Contrats de location',
    bigramme: 'CL',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyDescription',
    ],
    component: 'ContratsLocation',
    translation: 'contratLocation',
    active: true,
  },
  {
    name: 'Privilèges du Trésor en matière fiscale et en matière douanière',
    bigramme: 'TR',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'amountSecuredClaim',
      'shipClaimAmountDetails',
      'creancierManagerDenomination',
      'creancierManagerAddress',
    ],
    component: 'PrivilegesTresorFiscaleDouaniere',
    translation: 'PrivilegesTresorFiscaleDouaniere',
    active: true,
  },
  {
    name: 'Privilèges de la sécurité sociale et des régimes complémentaires',
    bigramme: 'SS',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'claimReference',
      'creancierDesignation',
      'creancierAddress',
      'employerRegistrationNumber',
      'claimReference',
      'riskAddress',
    ],
    component: 'PrivilegesSecuriteSocialeRegimesComplementaires',
    translation: 'PrivilegesSecuriteSocialeRegimesComplementaires',
    active: true,
  },
  {
    name: 'Warrants agricoles',
    bigramme: 'WA',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
    ],
    component: 'GarantiesAgricoles',
    translation: 'GarantiesAgricoles',
    active: false,
  },
  {
    name: 'Opérations de crédit-bail en matière mobilière',
    bigramme: 'CB',
    fields: [
      'inscriptionDate',
      'effectiveDate',
      'dueDate',
      'amount',
      'currency',
      'interestRates',
      'shipClaimAmountDetails',
      'propertyLocalization',
      'propertyDescription',
    ],
    component: 'OperationsCreditBailMobiliere',
    translation: 'OperationsCreditBailMobiliere',
    active: true,
  },
  {
    name: 'Logement indigne',
    bigramme: 'LI',
    fields: [],
    component: 'LHI',
    translation: 'LHI',
    active: false,
  },
  {
    name: 'Saisie pénale',
    bigramme: 'SP',
    fields: [],
    component: 'SaisiPenale',
    translation: 'SaisiPenale',
    active: false,
  },
];

export const nonPossessoryPledges = [
  {
    name: 'Animaux',
    bigramme: 'Animaux',
    translation: 'Animaux',
    active: true,
  },
  {
    name: 'Horlogerie et bijoux',
    bigramme: 'HorlogerieEtBijoux',
    translation: 'HorlogerieEtBijoux',
    active: true,
  },
  {
    name: 'Instruments De Musique',
    bigramme: 'InstrumentsDeMusique',
    translation: 'InstrumentsDeMusique',
    active: true,
  },
  {
    name: 'Materiels Mobiliers Et Produits A Usage Professionnel Non Vises Dans Les Autres Categories',
    bigramme: 'MaterielsMobiliersEtProduitsAUsageProfessionnelNonVisesDansLesAutresCategories',
    translation: 'MaterielsMobiliersEtProduitsAUsageProfessionnelNonVisesDansLesAutresCategories',
    active: true,
  },
  {
    name: 'Materiels A Usage Non Professionnel Autres Qu Informatiques',
    bigramme: 'MaterielsAUsageNonProfessionnelAutresQuInformatiques',
    translation: 'MaterielsAUsageNonProfessionnelAutresQuInformatiques',
    active: true,
  },
  {
    name: 'Materiels Lies Au Sport',
    bigramme: 'MaterielsLiesAuSport',
    translation: 'MaterielsLiesAuSport',
    active: true,
  },
  {
    name: 'Materiels Informatiques Et Accessoires',
    bigramme: 'MaterielsInformatiquesEtAccessoires',
    translation: 'MaterielsInformatiquesEtAccessoires',
    active: true,
  },
  {
    name: 'Meubles Meublants',
    bigramme: 'MeublesMeublants',
    translation: 'MeublesMeublants',
    active: true,
  },
  {
    name: 'Meubles Incorporels Autres Que Parts Sociales',
    bigramme: 'MeublesIncorporelsAutresQuePartsSociales',
    translation: 'MeublesIncorporelsAutresQuePartsSociales',
    active: true,
  },
  {
    name: 'Monnaies',
    bigramme: 'Monnaies',
    translation: 'Monnaies',
    active: true,
  },
  {
    name: 'Objets Art De Collection Ou D Antiquite',
    bigramme: 'ObjetsArtDeCollectionOuDAntiquite',
    translation: 'ObjetsArtDeCollectionOuDAntiquite',
    active: true,
  },
  {
    name: 'Parts Sociales',
    bigramme: 'PartsSociales',
    translation: 'PartsSociales',
    active: true,
  },
  {
    name: 'Produits D edition De La Presse Ou D Autres Industries Graphiques',
    bigramme: 'ProduitsDeditionDeLaPresseOuDAutresIndustriesGraphiques',
    translation: 'ProduitsDeditionDeLaPresseOuDAutresIndustriesGraphiques',
    active: true,
  },
  {
    name: 'Produits Liquides Non Comestibles',
    bigramme: 'ProduitsLiquidesNonComestibles',
    translation: 'ProduitsLiquidesNonComestibles',
    active: true,
  },
  {
    name: 'Produits Textiles',
    bigramme: 'ProduitsTextiles',
    translation: 'ProduitsTextiles',
    active: true,
  },
  {
    name: 'Produits Alimentaires',
    bigramme: 'ProduitsAlimentaires',
    translation: 'ProduitsAlimentaires',
    active: true,
  },
  {
    name: 'Autres',
    bigramme: 'Autres',
    translation: 'Autres',
    active: true,
  },
];
