import Analytics from 'analytics';
import googleAnalyticsPlugin from '@analytics/google-analytics';
import { removeCookie } from '@analytics/cookie-utils';

let analytics = null;

export function initiGoogleAnalytics() {
  const enable = !!localStorage.getItem('PRSMCookies');

  analytics = Analytics({
    app: 'PRSM',
    plugins: [
      googleAnalyticsPlugin({
        measurementIds: [process.env.VUE_APP_GOOGLE_ANALYTICS_ID],
        enabled: enable,
      }),
    ],
  });
  analytics.page();
}

export function enableTracking(value) {
  if (value) {
    analytics.plugins.enable(['google-analytics']).then(() => {
      analytics.page();
    });
  } else {
    analytics.plugins.disable(['google-analytics']).then(() => {
      const allCookies = document.cookie.split(';').join('=').split('=');
      const GACookies = allCookies.filter((cookie) => cookie.includes('_ga'));

      GACookies.forEach((cookie) => {
        removeCookie(cookie);
      });
    });
  }
}
