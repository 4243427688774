<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.propertyDescription') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.propertyDescription }">
            {{ data.propertyDescription || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.debtLocalisation') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.debtLocalisation }">
            {{ data.debtLocalisation || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDate }">
            {{ formatDate(data.actOrCourtDecisionDate) || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionType') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionType }">
            {{ data.actOrCourtDecisionType || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionAuthor') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionAuthor }">
            {{ data.actOrCourtDecisionAuthor || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDuration') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDuration }">
            {{ data.actOrCourtDecisionDuration || $t('formulaire.notSpecified') }}
          </p>
        </div>
      </div>
      <div class="flex-column">
        <p class="flex-column-content flex-column-group">
          <b>{{ $t('search.mentionOfCommissaryAgreement') }}:</b>
          {{ data.mentionOfCommissaryAgreement ? $t('oui') : $t('non') }}
        </p>
        <p class="flex-column-content flex-column-group">
          <b>{{ $t('search.pledge2342Agreement') }}:</b> {{ data.pledge2342Agreement ? $t('oui') : $t('non') }}
        </p>
        <p class="flex-column-content flex-column-group">
          <b>{{ $t('search.pledgeTransferAgreement') }}:</b> {{ data.pledgeTransferAgreement ? $t('oui') : $t('non') }}
        </p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.natureActivityuBusinessIdentification') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.natureActivityuBusinessIdentification }">
            {{ data.natureActivityuBusinessIdentification || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.propertyRegistrationNumber') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.propertyRegistrationNumber }">
            {{ data.propertyRegistrationNumber || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/formater';
export default {
  name: 'GageSansDepossession',
  data() {
    return {
      formatDate,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>