import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';
import Search from '@/views/Search.vue';
import Legal from '@/views/Legal.vue';
import Cookies from '@/views/Cookies.vue';
import RGPD from '@/views/RGPD.vue';
import Version from '@/views/Version.vue';
import NotFound from '@/views/NotFound.vue';
import SuretePrint from '@/views/SuretePrint.vue';

import BoatForm from '@/components/forms/BoatForm.vue';
import NoSirenForm from '@/components/forms/NoSirenForm.vue';
import ShipForm from '@/components/forms/ShipForm.vue';
import SirenForm from '@/components/forms/SirenForm.vue';
import AddressForm from '@/components/forms/AddressForm.vue';

import Results from '@/components/results/Results.vue';
import ResultsPrint from '@/components/results/ResultsPrint.vue';
import DebitorDetails from '@/components/results/DebitorDetails.vue';
import DebitorDetailsPrint from '@/components/results/DebitorDetailsPrint.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      nav: true,
      footer: true,
    },
    children: [
      {
        path: 'rechercheParBateau',
        name: 'BoatSearch',
        component: BoatForm,
        meta: {
          home: true,
          footerLegals: true,
        },
      },
      {
        path: 'rechercheParInformation',
        name: 'NoSirenSearch',
        component: NoSirenForm,
        meta: {
          home: true,
          footerLegals: true,
        },
      },
      {
        path: 'rechercheParNavire',
        name: 'ShipSearch',
        component: ShipForm,
        meta: {
          home: true,
          footerLegals: true,
        },
      },
      {
        path: 'rechercheParSiren',
        name: 'SirenSearch',
        component: SirenForm,
        meta: {
          home: true,
          footerLegals: true,
        },
      },
      {
        path: 'rechercheParAdresse',
        name: 'AddressSearch',
        component: AddressForm,
        meta: {
          home: true,
          footerLegals: true,
        },
      },
    ],
  },
  {
    path: '/resultats/',
    name: 'Search',
    component: Search,
    meta: {
      nav: true,
      footer: true,
      footerLegals: false,
    },
    children: [
      {
        path: 'debiteurs',
        name: 'Debitors',
        component: Results,
        meta: {
          nav: true,
          footer: true,
          footerLegals: false,
        },
      },
      {
        path: 'debiteurs/print',
        name: 'DebitorsPrint',
        component: ResultsPrint,
        meta: {
          nav: false,
          footer: false,
        },
      },
      {
        path: 'debiteurs/:id/suretes/print',
        name: 'DebitorsSuretesPrint',
        component: DebitorDetailsPrint,
        meta: {
          nav: false,
          footer: false,
        },
      },
      {
        path: 'suretes/:id',
        name: 'Surete',
        component: DebitorDetails,
        meta: {
          nav: true,
          footer: true,
          footerLegals: false,
        },
      },
      {
        path: 'debiteurs/:id/suretes/:identifierSurete/print',
        name: 'SuretePrint',
        component: SuretePrint,
        meta: {
          nav: false,
          footer: false,
        },
      },
    ],
  },
  {
    path: '/mentions-legales',
    name: 'Legal',
    component: Legal,
    meta: {
      nav: true,
      footer: true,
      footerLegals: true,
    },
  },
  {
    path: '/charte-gestion-des-cookies',
    name: 'Cookies',
    component: Cookies,
    meta: {
      nav: true,
      footer: true,
      footerLegals: true,
    },
  },
  {
    path: '/charte-rgpd',
    name: 'RGPD',
    component: RGPD,
    meta: {
      nav: true,
      footer: true,
      footerLegals: true,
    },
  },
  {
    path: '/version',
    name: 'Version',
    component: Version,
    meta: {
      nav: false,
      footer: false,
      footerLegals: true,
    },
  },
  {
    path: '/design-system',
    name: 'DesignSystem',
    component: () => import('@/views/DesignSystem.vue'),
    meta: {
      nav: false,
      footer: true,
      footerLegals: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      nav: true,
      footer: true,
      footerLegals: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (!to.meta.home) window.scrollTo(0, 0);
  },
});

export default router;
