import axios from 'axios';

export class Request {
  constructor({ baseURL }) {
    Object.assign(this, axios.create({ baseURL }));
    for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
      this['$' + method] = function () {
        return this[method].apply(this, arguments).then((res) => res && res.data);
      };
    }
  }
  setHeader(key, value) {
    this.defaults.headers.common[key] = value;
  }
}

const instance = new Request({ baseURL: '' });
const axiosHelper = (Vue, { router, store, apiUrl }) => {
  instance.router = router
  instance.defaults.baseURL = apiUrl;
  if (store) {
    store.$axios = instance;
  }

  instance.interceptors.request.use(
    async (config) => {

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status >= 500 && error.response?.status < 600) {
        if (instance.errorCallback) {
          instance.errorCallback();
        }
      }
      return Promise.reject(error);
    }
  );
  Object.defineProperties(Vue.config.globalProperties, {
    $axios: {
      get() {
        return instance;
      },
    },
  });
};


export { axiosHelper, instance };
