<template>
  <div
    class="surete-card"
    :class="{
      open: isCardOpen(data.uniqueIdentifier) || detailedPrint,
      'not-detailed-print': printMode && !detailedPrint,
      'detail-print': printMode && detailedPrint,
      'no-print': !printMode,
    }"
  >
    <div class="segment main">
      <div class="part">
        <h3>N°{{ data.uniqueIdentifier }}</h3>
        <p>{{ data.type.text }}</p>
        <p v-if="data.specialization.debitorExtraInfos">{{ data.specialization.debitorExtraInfos }}</p>
        <p class="property-type" v-if="data.type.bigramme === 'GD'">{{ propertyType }}</p>
      </div>
      <div class="part wider greffe">
        <p @click="openGreffeModal"><PinsIcon />{{ $t('search.greffeDe') }}{{ debitor.greffe?.name }}</p>
      </div>
      <div class="arrow-container print-hidden" @click="openOrCloseCard">
        <div class="arrow" :class="{ up: isCardOpen(data.uniqueIdentifier) || detailedPrint }"></div>
      </div>
    </div>
    <div class="segment further-down" :class="{ translated: !isCardOpen(data.uniqueIdentifier) || detailedPrint }">
      <div class="part print-invisible" :class="{ visible: checkIfShouldBeVisible('amount') }">
        <p class="bolder">{{ $t('search.debtAmount') }} :</p>
        <p>{{ formatPrice(data.amount) }}</p>
      </div>
      <div class="part print-invisible" :class="{ visible: checkIfShouldBeVisible('currency') }">
        <p class="bolder">{{ $t('search.currency') }} :</p>
        <p>{{ data.currency || '-' }}</p>
      </div>
      <div class="part print-invisible full-mobile" :class="{ visible: checkIfShouldBeVisible('interestRates') }">
        <p class="bolder">{{ $t('search.interestRate') }} :</p>
        <p>{{ formatPercent(data.interestRates) }}</p>
      </div>

      <div class="part fixed-opacity" :class="{ visible: checkIfShouldBeVisible('inscriptionDate') }">
        <p class="bolder">{{ $t('search.inscriptionDate') }}</p>
        <p>{{ formatDate(data.inscriptionDate) || $t('search.infoUnavailable') }}</p>
      </div>
      <div class="part print-invisible" :class="{ visible: checkIfShouldBeVisible('effectiveDate') }">
        <p class="bolder">{{ $t('search.effectiveDate') }}</p>
        <p>{{ formatDate(data.effectiveDate) || $t('search.infoUnavailable') }}</p>
      </div>
      <div class="part full print-invisible" :class="{ visible: checkIfShouldBeVisible('shipClaimAmountDetails') }">
        <p class="amount-detail bolder">{{ $t('search.ShipClaimAmountDetails') }} :</p>
        <p class="long-text">{{ data.specialization.ShipClaimAmountDetails || '-' }}</p>
      </div>
    </div>

    <SureteBlock
      v-for="debitor in data.debiteurs"
      :data="debitor"
      :key="`debitor${debitor.greffeCode}`"
      :title="getFirstSectionName"
      class="print-hidden"
    />
    <SureteBlock
      v-for="creancier in data.creanciers"
      :data="creancier"
      :key="`creancier${creancier.greffeCode}`"
      :title="getSecondSectionName"
      class="print-hidden"
    />
    <SureteBlock
      :data="data.propertyOwner"
      v-if="ownerShouldBeDisplayed"
      class="print-hidden"
      :title="$t('search.owner')"
    />

    <div class="segment border-top print-hidden" v-if="categoryDetails !== 'PrivilegesTresorFiscaleDouaniere'">
      <component :is="categoryDetails" :data="data.specialization" />
    </div>
    <div class="button-area print-hidden">
      <button class="secondary" @click="openGreffeModal">{{ $t('search.askCertification') }}</button>
      <p @click="printSurete">{{ $t('search.downloadContent') }} <Reload :class="{ visible: printing }" /></p>
    </div>
    <Teleport to="#app">
      <transition name="fade-modal">
        <GreffeModal v-if="contactingGreffe" @close="contactingGreffe = false" :greffe="greffe" :debitor="debitor" />
      </transition>
    </Teleport>
  </div>
</template>

<script>
import { mainCategories, nonPossessoryPledges } from '@/utils/infoEnum';
import { formatPrice, formatPercent, formatDate } from '@/utils/formater';

import PinsIcon from '@/assets/icons/pins.svg';
import People from '@/assets/icons/people.svg';
import Reload from '@/assets/icons/reload.svg';

import SureteBlock from '@/components/results/surete/SureteBlock.vue';
import GageSansDepossession from '@/components/results/surete/categories/GageSansDepossession.vue';
import NantissementsPartsSocietes from '@/components/results/surete/categories/NantissementsPartsSocietes.vue';
import PrivilegeVendeurFondsDeCommerce from '@/components/results/surete/categories/PrivilegeVendeurFondsDeCommerce.vue';
import NantissementsFondsDeCommerce from '@/components/results/surete/categories/NantissementsFondsDeCommerce.vue';
import DeclarationsCreances from '@/components/results/surete/categories/DeclarationsCreances.vue';
import HypothequesMaritimes from '@/components/results/surete/categories/HypothequesMaritimes.vue';
import ActesSaisieNavires from '@/components/results/surete/categories/ActesSaisieNavires.vue';
import ActeJugementTranslatifOuDroitsBateaux from '@/components/results/surete/categories/ActeJugementTranslatifOuDroitsBateaux.vue';
import HypothèquesFluviales from '@/components/results/surete/categories/HypothèquesFluviales.vue';
import ActesSaisieBateaux from '@/components/results/surete/categories/ActesSaisieBateaux.vue';
import ClausesReservePropriete from '@/components/results/surete/categories/ClausesReservePropriete.vue';
import ContratsLocation from '@/components/results/surete/categories/ContratsLocation.vue';
import PrivilegesTresorFiscaleDouaniere from '@/components/results/surete/categories/PrivilegesTresorFiscaleDouaniere.vue';
import MesuresInalienabiliteTribunal from '@/components/results/surete/categories/MesuresInalienabiliteTribunal.vue';
import PrivilegesSecuriteSocialeRegimesComplementaires from '@/components/results/surete/categories/PrivilegesSecuriteSocialeRegimesComplementaires.vue';
import GarantiesAgricoles from '@/components/results/surete/categories/GarantiesAgricoles.vue';
import OperationsCreditBailMobiliere from '@/components/results/surete/categories/OperationsCreditBailMobiliere.vue';
import GreffeModal from '@/components/modals/GreffeModal.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'Surete',
  data() {
    return {
      mainCategories,
      nonPossessoryPledges,
      contactingGreffe: false,
      greffe: null,
      printing: false,
      formatPrice,
      formatPercent,
      formatDate,
    };
  },
  components: {
    PinsIcon,
    People,
    GreffeModal,
    Reload,
    SureteBlock,
    GageSansDepossession,
    NantissementsPartsSocietes,
    PrivilegeVendeurFondsDeCommerce,
    NantissementsFondsDeCommerce,
    DeclarationsCreances,
    HypothequesMaritimes,
    ActesSaisieNavires,
    ActeJugementTranslatifOuDroitsBateaux,
    HypothèquesFluviales,
    ActesSaisieBateaux,
    ClausesReservePropriete,
    ContratsLocation,
    PrivilegesTresorFiscaleDouaniere,
    PrivilegesSecuriteSocialeRegimesComplementaires,
    GarantiesAgricoles,
    OperationsCreditBailMobiliere,
    MesuresInalienabiliteTribunal,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    detailedPrint: {
      type: Boolean,
    },
    debitor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      debitorOpenCardId: 'debitorOpenCardId',
    }),
    isOpen() {
      return this.data.uniqueIdentifier === this.debitorOpenCardId;
    },
    categoryDetails() {
      const currentCategorie = this.mainCategories.find((categorie) => categorie.bigramme === this.data.type.bigramme);
      if (!currentCategorie) return 'GageSansDepossession';
      return currentCategorie.component;
    },
    getFirstSectionName() {
      switch (this.data.type.text) {
        case 'Acte Jugement Translatif Ou Droits Bateaux':
          return this.$t('search.actJudgementDebitor');
        case 'Mesures Inalienabilite Tribunal':
          return this.$t('search.inalienabilityDebitor');
        case 'Operations Credit Bail Mobiliere':
          return this.$t('search.operationCreditDebitor');
        default:
          return this.$t('search.debitor');
      }
    },
    getSecondSectionName() {
      switch (this.data.type.text) {
        case 'Acte Jugement Translatif Ou Droits Bateaux':
          return this.$t('search.actJudgementCreditor');
        case 'Mesures Inalienabilite Tribunal':
          return this.$t('search.inalienabilityCreditor');
        case 'Operations Credit Bail Mobiliere':
          return this.$t('search.operationCreditCreditor');
        default:
          return this.$t('search.creditor');
      }
    },
    ownerShouldBeDisplayed() {
      const noOwnerCategories = [
        'Privileges Tresor Fiscale Douaniere',
        'Privileges Securite Sociale Regimes Complementaires',
      ];

      if (noOwnerCategories.includes(this.data.type.text) || !this.data.propertyOwner) return false;

      return true;
    },
    printMode() {
      return this.$route.name === 'DebitorsSuretesPrint' || this.$route.name === 'SuretePrint';
    },
    propertyType() {
      if (this.data.specialization.propertyType) {
        return `${
          this.nonPossessoryPledges.findIndex(
            (item) => item.name.toLowerCase() === this.data.specialization.propertyType.toLowerCase()
          ) + 1
        }. ${this.data.specialization.propertyType}`;
      }
      return '';
    },
  },
  methods: {
    getAddress(data) {
      return Object.values(data.address).filter((value) => value).length
        ? `${data.address.streetNumber || ''} ${data.address.street || ''} ${data.address.city || ''} ${
            data.address.postalCode || ''
          } ${data.address.country || ''}`
        : '-';
    },
    isCardOpen() {
      return this.isOpen;
    },
    openOrCloseCard() {
      this.$store.dispatch('setdebitorOpenCardId', this.data.uniqueIdentifier);
    },
    getNameOfLegalForm(object) {
      return object.lastName ? this.$t('search.name') : this.$t('search.legalForm');
    },
    getFirstNameOfDenomination(object) {
      return object.firstName ? this.$t('search.firstName') : this.$t('search.denomination');
    },
    checkIfShouldBeVisible(field) {
      let currentCategorie = this.mainCategories.find((categorie) => categorie.bigramme === this.data.type.bigramme);
      if (!currentCategorie) currentCategorie = this.mainCategories.find((categorie) => categorie.bigramme === 'GD');
      return currentCategorie.fields.includes(field);
    },
    async printSurete() {
      if (this.printing) return;
      this.printing = true;
      const blob = await this.$store.dispatch('printSurete', {
        identifierDebitor: this.$route.params.id,
        identifierSurete: this.data.uniqueIdentifier,
        fileName: `surete_${this.data.uniqueIdentifier}.pdf`,
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `surete_${this.data.uniqueIdentifier}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.printing = false;
    },
    async openGreffeModal() {
      if (!this.greffe) {
        this.greffe = await this.$store.dispatch('getGreffeInformation', { greffeCode: this.debitor.greffe.code });
      }
      this.contactingGreffe = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.surete-card {
  width: 100%;
  max-height: 144px;
  border-top: 6px solid #b8bec5;
  background-color: #f7f8f9;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0 17px;
  transition: all 200ms;
  overflow: hidden;
  position: relative;

  .segment {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 24px;
    padding: 24px 24px 32px;

    &.main {
      padding: 0 24px;
    }

    &.border-top {
      border-top: 6px solid $lightgrey-color;
      padding-top: 25px;
    }

    &.further-down {
      flex-wrap: wrap;
      margin-top: 40px;
      padding: 0 24px;

      .part {
        gap: 8px;
        opacity: 0;
        transition: all 200ms;

        &.fixed-opacity,
        &.visible {
          opacity: 1;
        }

        &.full {
          width: 100%;
          flex: unset;
        }
      }
    }

    &.translated {
      .part {
        transform: translateY(-50px);
        transition: all 200ms;

        &.visible {
          opacity: 0;
        }

        &.fixed-opacity {
          opacity: 1;
        }
      }
    }

    .part {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      transition: transform 200ms;

      .property-type {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 0.9rem;
        line-height: 1.1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.greffe {
        p {
          width: fit-content;
          cursor: pointer;
        }
      }

      &:first-child {
        gap: 8px;
      }

      &.center {
        justify-content: center;
      }
    }

    .section-title {
      color: $focus-color;
      font-family: $title-font;
      font-size: 14px;
      font-weight: bold;
      line-height: 18px;
      margin-bottom: 26px;
      width: 100%;

      svg {
        width: 24px;
        margin-right: 11px;
      }
    }

    .enonce {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
      line-height: 16px;
    }

    .content {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 14px;
      line-height: 18px;
    }

    .text-line {
      &.half {
        width: calc((100% - 24px) / 2);
      }
      &.quarter {
        flex-grow: 1;
        max-width: 25%;
      }

      p:first-child,
      .bolder {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        margin-right: 15px;
        white-space: nowrap;
        margin-bottom: 8px;
      }

      p:last-child {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 14px;
        line-height: 16px;
      }

      span {
        color: $darkblue-color;
        font-family: $text-font;
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
      }
    }

    h3 {
      color: $darkblue-color;
      font-family: $title-font;
      font-size: 16px;
      font-weight: bold;
      line-height: 17px;
    }

    p {
      font-family: $text-font;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      color: #848e9b;

      &.bolder {
        font-weight: bold;
        color: $darkblue-color;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        opacity: 0.6;
        transition: opacity 200ms;
      }
    }

    .arrow-container {
      position: absolute;
      bottom: 22px;
      right: 22px;
      padding: 10px;
      cursor: pointer;

      .arrow {
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $darkblue-color;
        transition: all 300ms;

        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }

  &:hover {
    border-top: 6px solid $darkblue-color;
    background-color: #f2f3f4;
    transition: all 200ms;

    p svg {
      opacity: 1;
    }
  }

  &.open {
    border-top: 6px solid #68b5e6;
    max-height: 3000px;
    transition: all 200ms;

    .part h3 {
      color: #68b5e6;
    }

    p svg {
      opacity: 1;
    }
  }

  .button-area {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 32px;

    button {
      padding: 11px;
    }

    p {
      display: flex;
      align-items: center;
      text-decoration: underline;
      cursor: pointer;
      color: #27374e;
      font-family: $text-font;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;

      svg {
        margin-left: 10px;
        width: 20px;
        opacity: 0;
        transition: 0.3s;

        &.visible {
          opacity: 1;
          animation: semi-rotation 1s linear infinite;
        }
      }
    }
  }
}

.amount-detail {
  color: $darkblue-color;
  font-family: $text-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 8px 0;
}

.long-text {
  color: $darkblue-color;
  font-family: $text-font;
  font-size: 14px;
  line-height: 16px;
}

@media (max-width: $layout-breakpoint-medium) {
  .surete-card {
    &.no-print {
      max-height: 220px;

      &.open {
        max-height: 3000px;
      }

      .segment {
        .part {
          flex: unset;

          &.full-mobile {
            width: 100%;
          }
        }

        .text-line {
          &.quarter {
            max-width: 45%;
          }
        }

        &.translated {
          .part {
            transform: none;

            &.fixed-opacity {
              transform: translateY(-150px);
            }
          }
        }
      }

      .button-area {
        flex-wrap: wrap;

        button,
        p {
          width: 100%;
          justify-content: center;
        }

        p {
          margin-left: 30px;
        }
      }
    }
  }
}

@media print {
  .surete-card {
    &.detail-print {
      .print-hidden:not(.button-area) {
        display: flex !important;
      }
      .print-invisible {
        opacity: 1 !important;
      }

      .segment {
        .arrow {
          display: none !important;
        }

        .text-line {
          padding: 12px 0;
          page-break-inside: avoid !important;
        }

        &.translated {
          .part {
            transform: none;
          }
        }
      }
    }

    &.not-detailed-print {
      page-break-inside: avoid !important;
      padding-bottom: 24px;

      .print-invisible {
        opacity: 0 !important;
        height: 0 !important;
        transform: none !important;
      }

      .segment {
        &.further-down {
          height: 0 !important;
          margin: 0 !important;
        }

        &.translated {
          .part {
            transform: none;
          }
        }

        .fixed-opacity {
          flex: unset;
          transform: translateY(-24px) !important;
        }
      }
    }
  }
}
</style>