<template>
  <div class="not-found view">
    <div class="content">
      <h1 class="title">404</h1>
      <p class="message">
        <span>{{ $t('page-non-trouvée') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>
.not-found.view {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 128px;
      font-weight: bold;
      margin-bottom: 48px;
    }

    .message {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;

      span {
        display: block;
        margin-bottom: 12px;
      }
    }
  }
}
</style>