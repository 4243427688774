<template>
  <div class="tabs" ref="searchSelect">
    <div
      class="tab"
      :class="{ selected: modelValue === option.value }"
      :value="option.value"
      @click="select"
      v-for="option in options"
      :key="option.value"
    >
      {{ option.label }}
    </div>
    <div
      id="selector"
      :class="animationDirection"
      :style="{
        left: `${selectedTabIndex * size}%`,
        right: `${options.length * size - (selectedTabIndex + 1) * size}%`,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      size: 100 / this.options.length,
      left: 0,
      right: this.options.length * this.size,
      animationDirection: 'right',
    };
  },
  computed: {
    selectedTabIndex() {
      return this.options.findIndex((option) => option.value === this.modelValue);
    },
  },
  watch: {
    selectedTabIndex(newIndex, oldIndex) {
      this.animationDirection = newIndex > oldIndex ? 'left' : 'right';
    },
  },
  methods: {
    select(e) {
      this.$emit('update:modelValue', e.target.getAttribute('value'));
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 32px;
  line-height: 32px;
  border-bottom: 2px solid #dee0e4;
  margin: 32px 0;

  .tab {
    width: 100%;
    height: 100%;
    color: $darkblue-color;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $focus-color;
    }

    &.selected {
      color: $focus-color;
      font-size: 12px;
      font-weight: bold;
    }
  }

  #selector {
    position: absolute;
    bottom: -2px;
    height: 2px;
    background-color: $focus-color;

    &.left {
      transition: left 0.25s ease-in 0.05s, right 0.3s ease-out;
    }

    &.right {
      transition: left 0.3s ease-out, right 0.25s ease-in 0.05s;
    }
  }
}
</style>