<template>
  <div class="segment border-top">
    <p class="section-title"><People />{{ title }}</p>
    <div class="text-line half">
      <p>{{ $t('search.SIREN') }}</p>
      <p>{{ data.siren || '-' }}</p>
    </div>
    <div class="text-line half">
      <p>{{ $t('search.addressOne') }}:</p>
      <p>{{ getAddress() || '-' }}</p>
    </div>
    <div class="text-line quarter">
      <p>{{ getNameOrLegalForm().label }}:</p>
      <p>{{ getNameOrLegalForm().content }}</p>
    </div>
    <div class="text-line quarter">
      <p>{{ getFirstNameOrDenomination() }}:</p>
      <p>{{ data.denomination || data.firstName }}</p>
    </div>

    <div class="text-line half">
      <p>{{ $t('search.foreignAddress') }}:</p>
      <p>{{ data.foreignAddress ? data.foreignAddress : '-' }}</p>
    </div>
  </div>
</template>

<script>
import People from '@/assets/icons/people.svg';

export default {
  name: 'SureteBlock',
  components: {
    People,
  },
  props: {
    data: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  methods: {
    getAddress() {
      return Object.values(this.data.address).filter((value) => value).length
        ? `${this.data.address.streetNumber || ''} ${this.data.address.street || ''}${
            this.data.address.street && this.data.address.postalCode ? ', ' : ' '
          }${this.data.address.postalCode || ''} ${this.data.address.city || ''}${
            this.data.address.city && this.data.address.country ? ', ' : ' '
          }${this.data.address.country || ''}`
        : '-';
    },
    getNameOrLegalForm() {
      return {
        label: this.data.firstName ? this.$t('search.name') : this.$t('search.legalForm'),
        content: this.data.firstName ? this.data.lastName || '-' : this.data.legalStatus?.name || '-',
      };
    },
    getFirstNameOrDenomination() {
      return this.data.firstName ? this.$t('search.firstName') : this.$t('search.denomination');
    },
  },
};
</script>

<style scoped lang="scss">
.segment {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 24px;
  padding: 24px 24px 32px;
  border-top: 6px solid $lightgrey-color;
  padding-top: 25px;
  page-break-inside: avoid;

  .section-title {
    color: $focus-color;
    font-family: $title-font;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 26px;
    width: 100%;

    svg {
      width: 24px;
      margin-right: 11px;
    }
  }

  .text-line {
    page-break-inside: avoid;

    &.half {
      width: calc((100% - 24px) / 2);
    }
    &.quarter {
      flex-grow: 1;
      max-width: 25%;
    }

    p:first-child {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-right: 15px;
      white-space: nowrap;
      margin-bottom: 8px;
    }

    p:last-child {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 14px;
      line-height: 16px;
    }

    span {
      color: $darkblue-color;
      font-family: $text-font;
      font-size: 14px;
      line-height: 16px;
      display: inline-block;
    }
  }

  p {
    font-family: $text-font;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    color: #848e9b;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      opacity: 0.6;
      transition: opacity 200ms;
    }
  }
}
</style>