<template>
  <footer>
    <div class="top-container">
      <div class="content">
        <div class="social-container">
          <div class="social-title">{{ $t('suivez-nous') }}</div>
          <div class="social-button-container">
            <a class="social-button" aria-label="Twitter" href="https://twitter.com/CNG_TC" target="_blank">
              <TwitterIcon />
            </a>
            <a
              class="social-button"
              aria-label="Linkedin"
              href="https://www.linkedin.com/company/cngtc/"
              target="_blank"
            >
              <LinkedinIcon />
            </a>
            <a class="social-button" aria-label="Facebook" href="https://www.facebook.com/CNGTC/" target="_blank">
              <FacebookIcon />
            </a>
            <a
              class="social-button"
              aria-label="YouTube"
              href="https://www.youtube.com/channel/UChapBLU3lVVfq7YzsAL_QdA"
              target="_blank"
            >
              <YoutubeIcon />
            </a>
          </div>
        </div>
        <div class="right-container">
          <p class="title">{{ $t('partnership') }}</p>
          <img src="@/assets/banquedesterritoiresLogo.jpeg" class="banque-territoire-logo" />
        </div>
      </div>
    </div>
    <div class="bottom-container" v-if="$route.meta.footerLegals">
      <div class="copyright">© {{ year }} {{ $t('copyright') }}</div>
      <div class="link-container">
        <router-link to="/mentions-legales" class="link">
          <span>{{ $t('mentions-legales') }}</span>
        </router-link>
        <div class="divider">-</div>
        <router-link to="/charte-gestion-des-cookies" class="link">
          <span>{{ $t('charte-cookies') }}</span>
        </router-link>
        <div class="divider">-</div>
        <router-link to="/charte-rgpd" class="link">
          <span>{{ $t('charte-rgpd') }}</span>
        </router-link>
        <div class="divider">-</div>
        <a href="https://www.cngtc.fr/" target="_blank" class="link">
          <span>{{ $t('CNGTC') }}</span>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import TwitterIcon from '@/assets/icons/social/Twitter.svg';
import LinkedinIcon from '@/assets/icons/social/Linkedin.svg';
import FacebookIcon from '@/assets/icons/social/Facebook.svg';
import YoutubeIcon from '@/assets/icons/social/Youtube.svg';

export default {
  name: 'CNGFooter',
  components: {
    TwitterIcon,
    LinkedinIcon,
    FacebookIcon,
    YoutubeIcon,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
footer {
  width: 100%;

  .top-container {
    width: 100%;
    background: $darkblue-color;

    .content {
      display: flex;
      height: 160px;
      gap: 2.5rem;
      align-items: center;
      justify-content: space-between;

      .right-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.5rem;
        gap: 0.6rem;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        color: $white-color;
        text-align: center;
        white-space: pre-line;
      }

      .social-title {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: bold;
        color: $white-color;
        text-align: center;
        white-space: pre-line;
      }

      .banque-territoire-logo {
        width: 150px;
      }

      .social-container {
        .social-button-container {
          display: flex;
          align-items: center;
          justify-content: center;

          .social-button {
            $size: 30px;
            width: $size;
            height: $size;
            border-radius: $size;
            background: $white-color;
            margin-right: 16px;

            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            outline: solid 0 $focus-color;
            transition: 0.2s ease-out;

            svg {
              width: 15px;
              max-height: 28px;
              transition: 0.2s ease-out;
            }

            &:hover {
              svg {
                opacity: 0.8;
                transform: scale(1.1);
              }
            }

            &:focus-visible {
              outline: solid 2px $focus-color;
            }

            &:active {
              svg {
                opacity: 0.7;
                transform: scale(0.95);
              }
            }
          }
        }
      }
    }
  }

  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 0;
    background: $accent-color;
    color: $white-color;
    font-size: 16px;

    .copyright {
      text-align: center;
      font-weight: 400;
      line-height: 24px;
      padding: 0 24px;
      margin-bottom: 24px;
    }

    .link-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 0 12px;
      line-height: 24px;
      font-weight: bold;

      .link {
        width: 100%;
        margin: 8px 0;
        text-align: center;
        justify-content: center;
        text-decoration: underline;
        outline: solid 0 $focus-color;
        transition: 0.2s ease-in;

        &:hover {
          opacity: 0.8;
        }

        &:focus-visible {
          border-bottom: solid 2px $focus-color;
        }
      }

      .divider {
        display: none;
        margin: 0 6px;
      }
    }
  }
}

@media (min-width: $layout-breakpoint-large) {
  footer {
    .bottom-container {
      .copyright {
        padding: 0;
        margin-bottom: 0;
      }

      .link-container {
        flex-wrap: nowrap;

        .link {
          width: auto;
          margin: 8px 0;
          justify-content: flex-start;
        }

        .divider {
          display: block;
        }
      }
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  footer {
    .top-container {
      padding: 3rem 0;

      .content {
        flex-direction: column-reverse;
        gap: 2.5rem;
        align-items: center;
        justify-content: center;

        .right-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 1.5rem;
          gap: 0.6rem;
        }
      }
    }
  }
}
</style>
