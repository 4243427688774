<template>
  <div class="toast" :class="type">
    <component class="icon" :is="icon" />
    <div class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue';

import ErrorIcon from '@/assets/icons/error.svg';
import InfoIcon from '@/assets/icons/info.svg';
import SuccessIcon from '@/assets/icons/success.svg';
import WarningIcon from '@/assets/icons/warning.svg';

export default {
  name: 'Toast',
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'error':
          return markRaw(ErrorIcon);
        case 'info':
          return markRaw(InfoIcon);
        case 'success':
          return markRaw(SuccessIcon);
        case 'warning':
          return markRaw(WarningIcon);
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* Status color */
$error-color: $red-color;
$info-color: #f1c40f;
$success-color: #2ecc71;
$warning-color: #e67e22;

.toast {
  position: relative;
  width: 320px;
  min-height: 64px;
  padding: 8px 12px;
  margin: 8px auto;
  background: $white-color;

  display: flex;
  align-items: center;

  box-shadow: 0 2px 24px -4px rgba($darkblue-color, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background: $darkblue-color;
  }

  .icon {
    $size: 24px;
    width: $size;
    height: $size;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .message {
    font-size: 14px;
    line-height: 1.2;
    color: $darkblue-color;
  }

  &.error {
    &::before {
      background: $error-color;
    }

    .icon {
      color: $error-color;
    }
  }
  &.info {
    &::before {
      background: $info-color;
    }

    .icon {
      color: $info-color;
    }
  }
  &.success {
    &::before {
      background: $success-color;
    }

    .icon {
      color: $success-color;
    }
  }
  &.warning {
    &::before {
      background: $warning-color;
    }

    .icon {
      color: $warning-color;
    }
  }
}
</style>
