<template>
  <div class="ship-form">
    <div class="section">
      <h3 class="group-title">{{ $t('formulaire.informations') }}</h3>
      <div class="three-quarter">
        <InputField v-model="ship.ShipRegistrationNumber" :placeholder="$t('formulaire.shipIdNumber')" />
      </div>
    </div>
    <div class="section">
      <h3 class="group-title">{{ $t('formulaire.safetyCategory') }}</h3>
      <div class="checkbox">
        <input
          type="checkbox"
          id="checkbox-1"
          name="checkbox"
          :checked="categoriesSelected.includes('HypothequesMaritimes')"
          @input="updateCategories('HypothequesMaritimes')"
        />
        <label for="checkbox-1">{{ $t('categories.HypothequesMaritimes') }}</label>
      </div>
      <div class="checkbox">
        <input
          type="checkbox"
          id="checkbox-2"
          name="checkbox"
          :checked="categoriesSelected.includes('ActesSaisieNavires')"
          @input="updateCategories('ActesSaisieNavires')"
        />
        <label for="checkbox-2">{{ $t('categories.ActesSaisieNavires') }}</label>
      </div>
      <span class="error-message" v-if="!categoriesSelected.length">{{ $t('error.noCategoriesSelected') }}</span>
    </div>
    <div class="section">
      <div class="button-container">
        <button
          class="primary contained"
          :disabled="!ship.ShipRegistrationNumber || !categoriesSelected.length"
          @click="getDebitors"
        >
          <SearchIcon class="icon" />
          <span>{{ $t('rechercher') }}</span>
        </button>
      </div>
      <div class="caution-message">{{ $t('formulaire.cautionMessage') }}</div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/icons/search.svg';
import InputField from '@/components/inputs/InputField.vue';
import ReCaptchaMixin from '@/mixins/ReCaptcha';

export default {
  name: 'ShipForm',
  mixins: [ReCaptchaMixin],
  data() {
    return {
      ship: {
        ShipRegistrationNumber: null,
      },
      categoriesSelected: ['HypothequesMaritimes', 'ActesSaisieNavires'],
    };
  },
  components: {
    SearchIcon,
    InputField,
  },
  methods: {
    async getDebitors() {
      const token = await this.getReCaptchaToken('submit')
      this.ship.Categories = this.categoriesSelected.join(',');
      await this.$store.dispatch('getDebitorList', { params: this.ship, token });
      this.$router.push({ name: 'Debitors', query: this.ship });
    },
    updateCategories(value) {
      this.categoriesSelected.includes(value)
        ? (this.categoriesSelected = this.categoriesSelected.filter((item) => item !== value))
        : this.categoriesSelected.push(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.ship-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;

    .checkbox {
      margin-left: 0;
    }

    .safety-cat {
      width: 100%;
      height: 42px;
      border: 2px solid #c9cdd2;
      padding: 13px 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #bec3c8;
      font-family: $text-font;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 13px;
      overflow: hidden;
    }

    .group-title {
      color: $darkblue-color;
      font-size: 16px;
      font-weight: bold;
      line-height: 17px;
      margin: 8px 0;
    }

    .three-quarter {
      width: 75%;
    }
  }

  .button-container {
    height: 40px;
    width: 168px;
  }

  .caution-message {
    width: 100%;
    border: 2px solid rgba(39, 55, 78, 0.3);
    background-color: rgba(73, 103, 118, 0.1);
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 10px;
  }
}

.button-text {
  text-transform: capitalize;
}

.error-message {
  color: $accent-color;
  font-family: $text-font;
  font-size: 10px;
  line-height: 11px;
}
</style>
