<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.boatDetails') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.boatName') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.boatName }">
            {{ data.boatName || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.boatRegistrationrNumber') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.boatName }">
            {{ data.boatRegistrationrNumber || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.boatRegistrationDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.boatRegistrationDate }">
            {{
              data.boatRegistrationDate
                ? data.boatRegistrationDate.toLocaleDateString('fr-FR')
                : $t('formulaire.notSpecified')
            }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.legal41221TransportCodeDeclarationDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.legal41221TransportCodeDeclarationDate }">
            {{ data.legal41221TransportCodeDeclarationDate || $t('formulaire.notSpecified') }}
          </p>
        </div>
      </div>
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.judicialInformationDecision') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDate }">
            {{ formatDate(data.actOrCourtDecisionDate) || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionType') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionType }">
            {{ data.actOrCourtDecisionType || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.publicOfficerDesignation') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.publicOfficerDesignation }">
            {{ data.publicOfficerDesignation || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.courtDesignation') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.courtDesignation }">
            {{ data.courtDesignation || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.purposeAndMainElementsOfTheActOrDecision') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.purposeAndMainElementsOfTheActOrDecision }">
            {{ data.purposeAndMainElementsOfTheActOrDecision || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/formater';
export default {
  name: 'ActeJugementTranslatifOuDroitsBateaux',
  data() {
    return {
      formatDate,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>