<template>
  <div class="modal-background" @click.self="$emit('close')">
    <div class="modal">
      <Close class="close" @click="$emit('close')" />
      <div class="text">
        <div class="flex-line">
          <div class="illustration"></div>
          <div class="flex-column">
            <h2>{{ $t('greffe.name', { name: greffe.name }) }}</h2>
            <p>
              {{ $t('greffe.address') }} <b>{{ greffe.address?.full }}</b>
            </p>
            <p>
              {{ $t('greffe.website') }} <a :href="`https://${greffe.website}`" target="_blank">{{ greffe.website }}</a>
            </p>
            <div class="button-link">
              <a class="secondary border no-margin link-button" :href="infogreffeLink" target="_blank">
                <LinkIcon />
                <span>{{ $t('search.orderList') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '@/assets/icons/close.svg';
import LinkIcon from '@/assets/icons/iconlink.svg';

export default {
  name: 'GreffeModal',
  components: {
    Close,
    LinkIcon,
  },
  props: {
    greffe: {
      type: Object,
    },
    debitor: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      infogreffeLink: null,
    };
  },
  async created() {
    this.infogreffeLink = await this.$store.dispatch('getIfgRedirecturl', { siren: this.debitor.siren });
  },
};
</script>

<style scoped lang="scss">
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($darkblue-color, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: relative;
    background: $white-color;
    width: 60vw;
    max-width: 812px;
    height: 290px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .flex-line {
      display: flex;
      gap: 1.5rem;
    }

    .button-link {
      margin-top: 3rem;
      height: 3rem;
      width: 21.2rem;

      svg {
        color: white;
        width: 24px;
        height: 24px;
        margin-right: 24px;
      }

      a {
        margin: 0;
        font-size: 1.1rem;
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 20px;
      cursor: pointer;
    }

    .illustration {
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      background-image: url('~@/assets/greffe.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    h2 {
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      margin-bottom: 6px;

      b {
        font-weight: bold;
      }

      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: $layout-breakpoint-medium) {
  .modal-background {
    padding: 24px;

    .modal {
      flex-direction: column;
      width: 100%;
      max-width: unset;
      padding: 24px;
      align-items: flex-start;

      .button-link {
        position: initial;
        width: 100%;
      }
    }
  }
}
</style>
