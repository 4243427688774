<template>
  <router-view />
  <cng-footer v-if="$route.meta.footer" />
  <CookieBanner />
  <ToastsContainer />
</template>

<script>
import CngFooter from '@/components/CNGFooter.vue';
import ToastsContainer from '@/components/ToastsContainer.vue';
import CookieBanner from '@/components/CookieBanner.vue';

export default {
  components: {
    CngFooter,
    ToastsContainer,
    CookieBanner,
  },
  created() {
    this.$store.dispatch('getLegalStatusList');
  },
};
</script>

<style lang="scss">
@import '@/styles/app.scss';
</style>
