<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.claimReference') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.claimReference }">
            {{ data.claimReference || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDate') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDate }"></p>
          {{ formatDate(data.actOrCourtDecisionDate) || $t('formulaire.notSpecified') }}
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionType') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionType }">
            {{ data.actOrCourtDecisionType || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionAuthor') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionAuthor }">
            {{ data.actOrCourtDecisionAuthor || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.actOrCourtDecisionDuration') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.actOrCourtDecisionDuration }">
            {{ data.actOrCourtDecisionDuration || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <p class="subtitle">{{ $t('search.otherMentions') }}</p>
        <p v-if="data.exemptsDebitorFromGivingNoticeForTheirBenefit" class="flex-column-title flex-column-group">
          {{ $t('search.exemptsDebitorFromGivingNoticeForTheirBenefit') }}
        </p>
        <p v-if="data.debitorDeclaresToBeOwner" class="flex-column-title">
          {{ $t('search.debitorDeclaresToBeOwner') }}
        </p>
        <p v-if="data.creancierConsentsThatNoNoticeOfWarrantShouldBeGiven" class="flex-column-title flex-column-group">
          {{ $t('search.creancierConsentsThatNoNoticeOfWarrantShouldBeGiven') }}
        </p>
        <p
          v-if="data.CreancierRequestsDebitorToNoticeThroughCommercialCourt"
          class="flex-column-title flex-column-group"
        >
          {{ $t('search.creancierRequestsDebitorToNoticeThroughCommercialCourt') }}
        </p>
        <p v-if="data.ownerTenantNoticeSentDate" class="flex-column-title flex-column-group">
          {{ $t('search.ownerTenantNoticeSentDate')
          }}{{ data.ownerTenantNoticeSentDate ? data.ownerTenantNoticeSentDate.toLocaleDateString('fr-FR') : '' }}
        </p>
        <p v-if="!data.noticeOfObjection" class="flex-column-title flex-column-group">{{ $t('search.noObjection') }}</p>
        <p v-else class="flex-column-title">{{ $t('search.objection') }}{{ $t('search.noticeOfObjectionDate') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/formater';
export default {
  name: 'GarantiesAgricoles',
  data() {
    return {
      formatDate,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>