export function formatPrice(price) {
  if (!price) {
    return '-';
  }
  return price;
}

export function formatPercent(percent) {
  if (percent === null) {
    return '-';
  }
  return `${percent}%`;
}

export function formatDate(date) {
  if (!date) return '';
  const dateToFormat = new Date(date);
  return dateToFormat.toLocaleDateString('fr-FR').replace(/\//g, '.');
}
