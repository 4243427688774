<template>
  <div class="debitor-card" @click="moveToSurete(debitor)">
    <div class="line">
      <p class="name">
        {{
          `${debitor.denomination || debitorFullName} ${
            debitor.legalStatus.name ? '(' + debitor.legalStatus.name + ')' : ''
          }`
        }}
      </p>
    </div>
    <div class="line">
      <p class="text-line">
        {{ debitor.address.country }} {{ debitor.address.postalCode ? ` | ${debitor.address.postalCode}` : '' }}
        {{ debitor.address.city ? ` | ${debitor.address.city}` : '' }}
      </p>
      <p class="weight-line smaller siren" v-if="debitor.siren">{{ $t('formulaire.siren') }}</p>
    </div>
    <div class="line">
      <p class="text-line">
        {{ debitor.address.streetNumber ? `${debitor.address.streetNumber} |` : '' }}
        {{ debitor.address.street }}
      </p>
      <p class="text-line" v-if="debitor.siren">{{ debitor.siren?.replace(/.{3}/g, '$& ') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DebitorCard',
  props: {
    debitor: {
      type: Object,
    },
  },
  data() {
    return {
      query: JSON.parse(localStorage.getItem('PRSMdebitorQueries')),
    };
  },
  computed: {
    debitorFullName() {
      return this.debitorFirstName ? `${this.debitorFirstName} ${this.debitorName}` : `${this.debitorName}`;
    },
    debitorName() {
      return this.debitor.lastName || '';
    },
    debitorFirstName() {
      return this.debitor.firstName || '';
    },
  },
  methods: {
    moveToSurete(debitorData) {
      const query = {
        siren: debitorData.siren,
        FirstnameDebitor: debitorData.firstName,
        nom: debitorData.lastName,
        legalStatus: debitorData.legalStatus.code,
        denomination: debitorData.denomination,
        CountryDebitor: debitorData.address.country,
        CityDebitor: debitorData.address.city,
        PostalCodeDebitor: debitorData.address.postalCode,
        StreetNameDebitor: debitorData.address.street,
        StreetNumberDebitor: debitorData.address.streetNumber,
        Categories: this.selectedCategories,
        Subcategories: this.selectedSubcategories,
      };
      this.$router.push({ name: 'Surete', params: { id: debitorData.identifier }, query: query });
    },
  },
};
</script>

<style scoped lang="scss">
.debitor-card {
  width: 100%;
  min-height: 124px;
  background-color: #f8f9f9;
  border-left: 6px solid #babec4;
  padding: 24px 33px 33px 18px;
  cursor: pointer;

  @media print {
    page-break-inside: avoid;
  }

  > .line {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    transition: all 400ms;
    > p {
      flex: 1;
      line-height: 1.2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden !important;
      max-width: 90%;
    }

    > p:first-child {
      padding-right: 3rem;
    }
  }

  &:hover {
    background-color: #f2f3f4;
    border-left: 6px solid $darkblue-color;
    transition: all 400ms;
  }

  .name {
    color: $darkblue-color;
    font-family: $title-font;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.2rem;
  }
}
</style>
