import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { removeCookie } from '@analytics/cookie-utils';

let appInsights = null;

export function initAppInsight() {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.VUE_APP_AI_KEY,
      connectionString: process.env.VUE_APP_AI_CNX,
      disableTelemetry: false,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableAjaxPerfTracking: true,
      enableUnhandledPromiseRejectionTracking: false,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });
  const cookieAgree = !!localStorage.getItem('PRSMCookies');
  enableCookie(cookieAgree);

  appInsights.loadAppInsights();
  appInsights.trackPageView();
}
export function enableCookie(value) {
  appInsights.getCookieMgr().setEnabled(value);

  if (!value) {
    const allCookies = document.cookie.split(';').join('=').split('=');
    const appInsightCookies = allCookies.filter((cookie) => cookie.includes('ai_'));

    appInsightCookies.forEach((cookie) => {
      removeCookie(cookie);
    });
  }
}
