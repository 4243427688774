<template>
  <div class="siren-form">
    <div class="form">
      <div class="siren-section">
        <h3 class="label">{{ $t('formulaire.siren').toUpperCase() }}</h3>
        <div class="two-third">
          <InputField v-model="debitor.siren" :placeholder="$t('formulaire.sirenPlaceholder') + '*'" type="siren"
            maxLength="11" @blur="displayError" />
        </div>
      </div>
      <div class="section">
        <h3 class="label">
          {{ $t('formulaire.safetyCategory') }}
        </h3>
        <DropdownInput :options="mainCategories" :chosenValues="categoryList" @updateList="updateList" />
      </div>
      <div class="section" v-if="categoryList.includes('GD')">
        <h3 class="label">
          {{ $t('categories.GageSansDepossession') }}
        </h3>
        <DropdownInput :options="nonPossessoryPledges" :chosenValues="nonPossessoryPledgesList"
          @updateList="updateNonPossessoryPledgesList" />
      </div>
      <div class="section" v-if="categoryList.includes('HM')">
        <h3 class="label">
          {{ $t('categories.HypothequesMaritimes') }}
        </h3>
        <InputField v-model="debitor.ShipRegistrationNumber" :placeholder="$t('formulaire.shipIdNumber')" />
      </div>
      <div class="section" v-if="categoryList.includes('HF')">
        <h3 class="label">
          {{ $t('categories.HypothequesFluviales') }}
        </h3>
        <InputField v-model="debitor.BoatRegistrationNumber" :placeholder="$t('formulaire.boatIdNumber')" />
      </div>
    </div>
    <!-- SUBMIT SECTION -->
    <div class="button-container">
      <button class="primary contained" :disabled="!sirenIsCorrect" @click="getDebitors">
        <SearchIcon class="icon" />
        <span>{{ $t('rechercher') }}</span>
      </button>
    </div>
    <div class="caution-message">
      {{ $t('formulaire.cautionMessage') }}
      <br />
      <br />
      {{ $t('formulaire.cautionWarrantsMessage') }}
    </div>
    <div class="caution-message error" v-if="errorMessage" v-html="errorMessage"></div>
  </div>
</template>

<script>
import InputField from '@/components/inputs/InputField.vue';
import SearchIcon from '@/assets/icons/search.svg'
import ReCaptchaMixin from "@/mixins/ReCaptcha"

import { mainCategories, nonPossessoryPledges } from '@/utils/infoEnum';
import DropdownInput from '@/components/inputs/DropdownInput.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'SirenForm',
  components: { InputField, SearchIcon, DropdownInput },
  mixins: [ReCaptchaMixin],
  data() {
    return {
      mainCategories,
      nonPossessoryPledges,
      resultBuffer: null,
      errorMessage: null,
      debitor: {
        siren: '',
        BoatRegistrationNumber: '',
        ShipRegistrationNumber: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      categoryList: 'categoryList',
      nonPossessoryPledgesList: 'nonPossessoryPledgesList',
    }),
    sirenIsCorrect() {
      return this.debitor.siren.trim().replaceAll(' ', '').length === 9;
    },
  },
  watch: {
    'debitor.siren'() {
      this.errorMessage = null;
    },
  },
  methods: {
    async getDebitors() {
      if (!this.debitor.siren) return;
      this.errorMessage = null;

      const query = this.debitor;
      query.typesSuretes = this.categoryList.concat(this.nonPossessoryPledgesList);

      query.siren = query.siren.split(' ').join('');

      if (this.categoryList.length) query.Categories = this.categoryList.join(',');

      if (this.categoryList.includes('GD') && this.nonPossessoryPledgesList.length) {
        query.Subcategories = this.nonPossessoryPledgesList.join(',');
      }
      if (this.categoryList.includes('HM')) {
        query.ShipRegistrationNumber = this.debitor.ShipRegistrationNumber;
      }
      if (this.categoryList.includes('HF')) {
        query.BoatRegistrationNumber = this.debitor.BoatRegistrationNumber;
      }

      try {

        const token = await this.getReCaptchaToken('submit')

        await this.$store.dispatch('getDebitorList', { params:query, token });


        this.$router.push({ name: 'Debitors', query: query });
      } catch (e) {
          this.errorMessage = this.$t('error.defaultError', {traceId: e.response.data.traceId});
      }
    },
    updateList(option) {
      if (this.categoryList.includes(option)) this.$store.dispatch('removeCategory', option);
      else if (!option) this.$store.dispatch('clearCategoryList');
      else this.$store.dispatch('addCategory', option);
    },
    updateNonPossessoryPledgesList(option) {
      if (this.nonPossessoryPledgesList.includes(option)) {
        this.$store.dispatch('removeNonPossessoryPledgeCategory', option);
      } else if (!option) this.$store.dispatch('clearNonPossessoryPledgeCategory');
      else this.$store.dispatch('addNonPossessoryPledgeCategory', option);
    },
    displayError() {
      if (!this.sirenIsCorrect) this.errorMessage = this.$t('error.invalidSiren');
    },
  },
};
</script>

<style lang="scss" scoped>
.siren-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  .form {
    width: 100%;

    .section {
      width: 100%;
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .line {
        width: 100%;
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }

      .space {
        justify-content: space-between;
      }

      .refresh-infos {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .refresh-text {
          opacity: 0.5;
          color: $darkblue-color;
          font-family: $text-font;
          font-size: 10px;
          font-style: italic;
          line-height: 11px;
          margin-right: 12px;
        }

        .refresh-icon {
          background-color: $darkblue-color;
          height: 26px;
          width: 26px;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          opacity: 1;

          &:active {
            opacity: 0.7;
          }

          &:hover {
            background-color: lighten($darkblue-color, 20%);
          }

          svg {
            color: white;
            width: 100%;
            height: 100%;
          }
        }
      }

      .all-category {
        height: 42px;
        width: 397px;
        border: 2px solid #caced3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 13px 0 16px;

        span {
          color: $darkblue-color;
          font-family: $text-font;
          font-size: 12px;
          font-weight: bold;
          line-height: 13px;
        }
      }
    }
  }

  .button-container {
    flex-shrink: 0;
    height: 40px;
    width: 168px;
  }

  .caution-message {
    width: 100%;
    border: 2px solid rgba(39, 55, 78, 0.3);
    background-color: rgba(73, 103, 118, 0.1);
    color: $darkblue-color;
    font-family: $text-font;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 15px;
    padding: 10px;

    &.error {
      color: $red-color;
      border-color: $red-color;
      background-color: rgba($red-color, 0.05);
    }
  }
}

.label {
  color: $darkblue-color;
  font-size: 16px;
  font-weight: bold;
  line-height: 17px;
  margin: 8px 0;
}

.quarter {
  width: 25%;
}

.half {
  width: 50%;
}

.two-third {
  width: 75%;
}

.three-quarter {
  width: 75%;
}

.full {
  width: 100%;
}

button span {
  text-transform: capitalize;
}

.error-message {
  color: $accent-color;
  font-family: $text-font;
  font-size: 10px;
  line-height: 11px;
}
</style>
