import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state = {
  debitorList: [],
  categoryList: [],
  nonPossessoryPledgesList: [],
  shipCategories: [],
  boatCategories: [],
  lastUsedParams: null,
  debitorOpenCardId: null,
  legalStatusList: null,
};

const namespaced = false;

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
