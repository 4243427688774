import { createApp } from 'vue';
import i18n from './i18n';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { axiosHelper } from './utils/axios';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { initAppInsight } from '@/utils/appInsight';
import { initiGoogleAnalytics } from '@/utils/googleAnalytics';
import {VueReCaptcha} from 'vue-recaptcha-v3';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(axiosHelper, { apiUrl: process.env.VUE_APP_API_URL, store, router });
app.use(VueReCaptcha, {siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA});
app.use(i18n);

initAppInsight();
initiGoogleAnalytics()

gsap.registerPlugin(ScrollToPlugin);

app.mount('#app');
