<template>
  <div class="categories-details">
    <div class="title">{{ $t('search.details') }}</div>
    <div class="flex-row">
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.shipIdentification') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipName') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipName }">
            {{ data.shipName || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipBoatType') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipBoatType }">
            {{ data.shipBoatType || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipBoatGauge') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipBoatGauge }">
            {{ data.shipBoatGauge || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipRegitrationPort') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipRegitrationPort }">
            {{ data.shipRegitrationPort || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipBoatNationality') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipBoatNationality }">
            {{ data.shipBoatNationality || $t('formulaire.notSpecified') }}
          </p>
        </div>
      </div>
      <div class="flex-column">
        <p class="subtitle">{{ $t('search.otherDetails') }}</p>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipBoatAmount') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipBoatAmount }">
            {{ data.shipBoatAmount === null ? $t('formulaire.notSpecified') : formatPrice(data.shipBoatAmount) }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipBoatFees') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipBoatFees }">
            {{ data.shipBoatFees || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.shipBoatKeeper') }}</p>
          <p class="flex-column-content" :class="{ placeholder: !data.shipBoatKeeper }">
            {{ data.shipBoatKeeper || $t('formulaire.notSpecified') }}
          </p>
        </div>
        <div class="flex-column-group">
          <p class="flex-column-title">{{ $t('search.observations') }}</p>
          <p class="flex-column-content" v-if="!data.mentions?.length">{{ $t('formulaire.notSpecified') }}</p>
          <p v-for="mention in data.mentions" :key="mention" :class="{ placeholder: !data.observations }">
            {{ mention }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from '@/utils/formater';

export default {
  name: 'ActesSaisieNavires',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    };
  },
};
</script>